import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import CXLoading from "components/CXLoading";
import { apiGetWithToken, generateQueryUrl } from 'lib/api';
import { BarChart, Bar, Rectangle, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const CXHorizontalBarchart = ({ title, siteId, tableId, viewId, primary, secondary, filter, primaryLabel, secondaryLabels, limit, bgColors, bgStrokeColors, activeBgColors, activeStrokeColors, verboseLogging, setApiUrl, messageApi }) => {
  const [loading, setLoading] = useState(true);
  const [table, setTable] = useState([]);
  const [xAxisKeys, setxAxisKeys] = useState([]);
  const [xAxisNames, setxAxisNames] = useState([]);
  const [yAxisKey, setyAxisKey] = useState('');
  const [yAxisName, setyAxisName] = useState('');

  useEffect(() => {
    if (!loading) {
      verboseLogging && console.log('Not loading');
      return;
    }
    if (!siteId) {
      verboseLogging && console.log('No siteId');
      return;
    }
    if (!tableId && !viewId) {
      verboseLogging && console.log('No tableId or viewId');
      return;
    }

    verboseLogging && console.log('Fetching graph data...');

    const url = generateQueryUrl(siteId, viewId, tableId, primary, secondary, filter, limit);
    setApiUrl && setApiUrl(url.toString());
    apiGetWithToken(url.toString(),
      () => { setLoading(true); },
      (data) => {
        let xKeys = [];
        let xNames = [];
        for (let i = 0; i < data.view.fieldList.length; i++) {
          if (i === 0) {
            setyAxisKey(data.view.fieldList[i].internalName);
            setyAxisName(data.view.fieldList[i].name);
          } else {
            xKeys.push(data.view.fieldList[i].internalName);
            xNames.push(data.view.fieldList[i].name);
          }
        }
        setxAxisKeys(xKeys);
        setxAxisNames(xNames);

        console.log(data);
        setTable(data.view.fieldValues);
      },
      (err) => {
        console.log(err);
        messageApi && messageApi.error(err.message);
      },
      () => { setLoading(false); }
    );

  }, [loading, siteId, tableId, viewId, filter, primary, secondary, limit, setApiUrl, verboseLogging, messageApi]);

  function fillColors(passed, defaultColor) {
    let arr = passed ? passed.split(',') : [];
    for (let i = arr.length; i < xAxisKeys.length; i++) {
      if (i === 0) {
        arr[i] = defaultColor;
      } else {
        arr[i] = arr[i - 1];
      }
    }
    return arr;
  }

  // Make sure we have enough of all the color values.
  const bgColorArr = fillColors(bgColors, '#55f');
  const bgStrokeArr = fillColors(bgStrokeColors, '#333');
  const activeStrokeArr = fillColors(activeStrokeColors, '#555');
  const activeBgArr = fillColors(activeBgColors, '#77e');

  const yAxisLabel = primaryLabel ? primaryLabel : yAxisName;
  let xAxisLabels = xAxisNames;
  if (secondaryLabels) {
    const l = secondaryLabels.split(',');
    for (let i = 0; i < l.length; i++) {
      console.log(l);
      xAxisLabels[i] = l[i];
    }
  }

  const Inside = () => {
    if (loading) {
      return <CXLoading text="Loading graph..." />
    }

    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={table}
          layout='vertical'
          margin={{
            top: 20,
            right: 20,
            left: 40,
            bottom: 10,
          }}
        >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis hide axisLine={false} type="number" />
          <YAxis
            type="category"
            axisLine={false}
            tickLine={false}
            dataKey={yAxisKey}
            name={yAxisLabel}
          />
          <Tooltip />
          <Legend />
          {xAxisKeys.map((name, i) => {
            return (
              <Bar
                dataKey={name}
                name={xAxisLabels[i]}
                fill={bgColorArr[i]}
                stroke={bgStrokeArr[i]}
                activeBar={
                  <Rectangle fill={activeBgArr[i]} stroke={activeStrokeArr[i]} />
                }
              />
            )
          })}

        </BarChart>
      </ResponsiveContainer>
    );
  }

  if (!title) {
    return <Inside />;
  }
  return (
    <>
      <Card title={title} style={{ width: '100%' }}>
        <Inside />
      </Card>
    </>
  );
};

export default CXHorizontalBarchart;