import React from "react";

import {
    FolderTwoTone,
    FolderOpenTwoTone,
    FilePdfTwoTone,
    FileImageTwoTone,
    FileWordTwoTone,
    FileExcelTwoTone,
    FileTextTwoTone,
    GlobalOutlined,
    LaptopOutlined,
    UpSquareTwoTone,
    FileUnknownTwoTone,
    SyncOutlined,
    ClockCircleFilled,
} from '@ant-design/icons';

export function AppIcon({ file, currentFolderId }) {
    if (file.processingStatus) {
        if (file.processingStatus.toLowerCase() === 'processing') {
            return <SyncOutlined spin style={{ color: '#8b86ca' }} />
        }
        if (file.processingStatus.toLowerCase() === 'queued') {
            return <ClockCircleFilled style={{ color: '#8b86ca' }} />
        }
    }

    if (file.icon === 'LaptopOutlined') {
        return <LaptopOutlined style={{ color: '#8b86ca' }} spin={file.isSyncing === true ? true : false} />;
    }
    if (file.icon === 'GlobalOutlined') {
        return <GlobalOutlined style={{ color: '#8b86ca' }} spin={file.isSyncing === true ? true : false} />;
    }
    if (file.isFolder) {
        if (file.name === '<prev folder>') {
            return <UpSquareTwoTone twoToneColor="#8b86ca" />
        } else if (file.tableItemId === currentFolderId) {
            return <FolderOpenTwoTone twoToneColor="#8b86ca" />
        }
        return <FolderTwoTone twoToneColor='#8b86ca' />
    }
    if (file.name.endsWith('.pdf')) {
        return <FilePdfTwoTone twoToneColor="#8b86ca" />;
    }
    if (file.name.endsWith('.png') || file.name.endsWith('.jpg') || file.name.endsWith('.jpeg')) {
        return <FileImageTwoTone twoToneColor="#8b86ca" />;
    }
    if (file.name.endsWith('.txt')) {
        return <FileTextTwoTone twoToneColor="#8b86ca" />;
    }
    if (file.name.endsWith('.doc') || file.name.endsWith('.docx')) {
        return <FileWordTwoTone twoToneColor="#8b86ca" />;
    }
    if (file.name.endsWith('.xls') || file.name.endsWith('.xlsx')) {
        return <FileExcelTwoTone twoToneColor="#8b86ca" />;
    }
    return <FileUnknownTwoTone twoToneColor="#8b86ca" />;
}
