import React, { useEffect, useState } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { apiGetWithToken, getApiUrl } from 'lib/api';
import './style.css'

// https://www.npmjs.com/package/powerbi-client-react?activeTab=readme

const CXPowerBI = ({ siteId, reportId }) => {
  const [accessToken, setAccessToken] = useState(undefined);
  const [embedUrl, setEmbedUrl] = useState(undefined);

  useEffect(() => {
    if (!siteId || !reportId) {
      return;
    }

    apiGetWithToken(getApiUrl(`/api/site/${siteId}/mstoken?reportId=${reportId}`),
      () => { },
      (data) => {
        console.log(data);
        setAccessToken(data.accessToken);
        setEmbedUrl(data.embedUrl);
      },
      (err) => {
        console.log(err);
        // messageApi && messageApi.error(err.message);
      },
      () => { }
    );

  }, [siteId, reportId]);


  return (
    <PowerBIEmbed

      embedConfig={{
        type: 'report',   // Supported types: report, dashboard, tile, visual, qna, paginated report and create
        id: reportId,
        embedUrl: embedUrl,
        accessToken: accessToken,
        tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
        settings: {
          panes: {
            filters: {
              expanded: false,
              visible: false
            }
          },
          background: models.BackgroundType.Transparent,
          // Shawn Stuff
          filterPaneEnabled: true,
          navContentPaneEnabled: false,
          // layoutType: models.LayoutType.Custom,
          // customLayout: {
          //   displayOption: models.DisplayOption.FitToWidth
          // }
        }
      }}

      eventHandlers={
        new Map([
          ['loaded', function () { console.log('Report loaded'); }],
          ['rendered', function () { console.log('Report rendered'); }],
          ['error', function (event) { console.log(event.detail); }],
          ['visualClicked', () => console.log('visual clicked')],
          ['pageChanged', (event) => console.log(event)],
        ])
      }

      cssClassName={"reportClass"}

      getEmbeddedComponent={(embeddedReport) => {
        console.log('report', embeddedReport);
        //this.report = embeddedReport;// as Report;
      }}
    />
  );
}

export default CXPowerBI;