import React from "react";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { FacebookLoginButton, MicrosoftLoginButton, GoogleLoginButton } from "react-social-login-buttons";
import { getApiUrl, apiPostWithToken, getRegion, getRegionName } from 'lib/api';
import { GoogleLogin } from '@react-oauth/google';
import { Flex } from "antd";
import { PublicClientApplication, InteractionRequiredAuthError, BrowserAuthError, BrowserAuthErrorCodes } from "@azure/msal-browser";

const MICROSOFT_CLIENT_ID = '3474631d-ffb9-4e28-9eaa-c8d5c0aa3a2d';
//const MICROSOFT_REDIRECT_URI = 'http://localhost:8000/api/microsoft-auth';
//const MICROSOFT_REDIRECT_URI = 'http://localhost:3000/login/microsoft-auth';
//const GOOGLE_CLIENT_ID = '386932037035-k8v833noqjk7m4.apps.googleusercontent.com'; // TODO Get a real one


function RegionFlag(args) {
    const region = String(args.region);
    const base = '/static/flags/';
    let path = '';
    if (region === '') {
        path = base + 'globe.png';
    } else if (region === 'dev') {
        path = base + 'dev.png';
    } else if (region.length === 2) {
        path = base + "code/" + region + ".png";
    } else {
        return '';
    }
    return (
        <img height="48" width="48" alt="flag" src={path}></img>
    )
};

export default function LoginSSO({ loading, setLoading, setLoginMessage, tryToLogin }) {
    async function MicrosoftLogin() {
        setLoading(true);
        const msalConfig = {
            auth: {
                clientId: MICROSOFT_CLIENT_ID,
                authority: "https://login.microsoftonline.com/common",
            },
        };

        function handleAuthError(err) {
            console.error('SSO error', err);;
            if (err instanceof BrowserAuthError) {
                switch (err.errorCode) {
                    case BrowserAuthErrorCodes.userCancelled:
                        setLoginMessage('Authentication attempt cancelled: ' + err.errorMessage);
                        break;
                    case BrowserAuthErrorCodes.popupWindowError:
                        setLoginMessage('Unable to open popup for authentication: ' + err.errorMessage);
                        break;
                    default:
                        setLoginMessage('Failed to authenticate with Microsoft: ' + err.errorMessage);
                }
            } else {
                setLoginMessage('Failed to authenticate with Microsoft: ' + err.errorMessage);
            }
            setLoading(false);
        }

        const msalInstance = new PublicClientApplication(msalConfig);
        await msalInstance.initialize();
        let username = '';
        try {
            const loginResponse = await msalInstance.loginPopup({ prompt: 'select_account' });
            // console.log('loginResponse', loginResponse);
            const accountId = loginResponse.account.homeAccountId;
            // console.log('accountId', accountId);
            const accounts = msalInstance.getAllAccounts();
            // console.log('accounts', accounts);
            // console.log('accounts', accounts[0]);
            username = accounts[0].username;
            msalInstance.setActiveAccount(accounts[0]);
        } catch (err) {
            handleAuthError(err);
            return;
        }

        async function loginToMicrosoft(accessToken, email) {
            const values = {
                'accessToken': accessToken,
                'email': email,
            }
            return await tryToLogin(getApiUrl('/api/login-microsoft'), values);
        }

        const tokenRequest = {
            scopes: ["User.Read"],
        };
        msalInstance
            .acquireTokenSilent(tokenRequest)
            .then(tokenResponse => {
                // console.log('tokenResponse', tokenResponse);
                console.log('Received silent token response');
                loginToMicrosoft(tokenResponse.accessToken, username);
            })
            .catch(async (err) => {
                if (err instanceof InteractionRequiredAuthError) {
                    // fallback to interaction when silent call fails
                    console.log('Require popup for token response');
                    msalInstance.acquireTokenPopup(tokenRequest)
                        .then(function (accessTokenResponse) {
                            loginToMicrosoft(accessTokenResponse.accessToken, username);
                        })
                        .catch(function (err) {
                            handleAuthError(err);
                            return;
                        });
                }

                // handle other errors
                handleAuthError(err);
                return;
            })
    }

    // const responseMessage = (response) => {
    //     console.log(response);
    // };

    // const errorMessage = (error) => {
    //     console.log(error);
    // };

    const region = getRegion();;
    const regionName = getRegionName();
    let regionText = regionName + ' Server';


    return (
        // <GoogleOAuthProvider clientId={{ GOOGLE_CLIENT_ID }}>

        <Flex vertical>
            <Flex style={{ textAlign: 'center' }} vertical>
                <div><img alt="X" src="/static/ClearyX-logo-dark.png" height="20px"></img></div>
                <div style={{ height: "20px" }}></div>
                <div><RegionFlag region={region} /></div>
                <div><b>{regionText}</b></div>
            </Flex>
            <Flex align="flex-end" flex="1">
                <div style={{ textAlign: 'center' }}>
                    {/* <FacebookLoginButton iconSize="18px" style={styles.button} onClick={() => alert("Hello")} /> */}
                    <MicrosoftLoginButton iconSize="18px" onClick={() => MicrosoftLogin()} />
                    {/* <GoogleLogin onSuccess={responseMessage} onError={errorMessage} /> */}
                    {/* <GoogleLoginButton iconSize="18px" style={styles.button} onClick={() => alert("Hello")} /> */}
                </div>
            </Flex>
        </Flex >
        // /* </GoogleOAuthProvider> */ 
    );
}