import React from 'react';
import { Menu, Flex } from 'antd';
import loadable from "@loadable/component";
import { Link } from "react-router-dom";

function getItems(siteId) {
    function getItem(label, link, icon, children) {
        const DynamicIcon = loadable(props =>
            import(`@ant-design/icons/es/icons/${props.type}.js`)
                .catch(err => import(`@ant-design/icons/es/icons/WarningOutlined.js`)))

        let linkicon = '';
        let linklabel = label;
        if (icon && link) {
            linkicon = <Link to={link}><DynamicIcon type={icon} /></Link>;
        } else if (icon) {
            linkicon = <DynamicIcon type={icon} />;
        } else if (link) {
            //linkicon = <Link to={link}></Link>;
            linklabel = <Link to={link}>{label}</Link>;
        }

        return {
            'icon': linkicon,
            'children': children,
            'label': linklabel,
            'key': label,
        };
    }

    return [
        getItem('Home', '/admin/' + siteId, 'HomeOutlined'),
        getItem('Site Settings', '', 'SettingOutlined', [
            getItem('General', '/admin/' + siteId + '/settings/general'),
            getItem('Modules', '/admin/' + siteId + '/settings/modules'),
            getItem('Navigation', '/admin/' + siteId + '/settings/navigation'),
            getItem('Security', '/admin/' + siteId + '/settings/security'),
        ]),
        getItem('Pages', '', 'CopyOutlined', [
            getItem('Create New', '/admin/' + siteId + '/pages/create'),
            getItem('View All', '/admin/' + siteId + '/pages'),
        ]),
        getItem('Tables', '/admin/' + siteId + '/tables', 'TableOutlined'),
        getItem('Graph Explorer', `/admin/${siteId}/graphs`, 'LineChartOutlined'),
        getItem('Question Bundles', '/admin/' + siteId + '/bundles', 'QuestionCircleOutlined'),
        { type: "divider" },
        getItem('Users', '/admin/' + siteId + '/users', 'UserOutlined'),
        getItem('Groups', '/admin/' + siteId + '/groups', 'GroupOutlined'),
        getItem('Roles', '/admin/' + siteId + '/roles', 'AuditOutlined'),
        { type: "divider" },
        getItem('Back to site', '/site/' + siteId, 'LogoutOutlined'),
    ];
};

const AdminMenu = ({ siteId, includeLogo }) => {
    const items = getItems(siteId);

    const logo = includeLogo ? (
        <Flex id="logo" style={{ margin: '30px 0px 20px 30px' }} >
            <img
                alt="logo"
                src='/static/CORA-Logo.png'
                style={{ maxHeight: '60px', maxWidth: '160px' }}
            />
        </Flex >
    ) : <></>;

    return (
        <Flex style={{ height: '100%' }} vertical>
            <Flex flex="1" style={{ minWidth: '260px' }} vertical>
                {logo}
                <Menu
                    theme="light"
                    style={{ height: '100%', width: '260px' }}
                    defaultSelectedKeys={['Home']}
                    defaultOpenKeys={['Site Settings']}
                    mode="inline"
                    items={items}
                />
            </Flex>
        </Flex>
    );
};

export default AdminMenu;