import React, { useEffect } from "react";
import { useOutletContext } from "react-router-dom";

const AdminSettingsNavigation = () => {
    const { site, siteLoading, setBreadcrumb } = useOutletContext();

    useEffect(() => {
        if (siteLoading) {
            return;
        }
        setBreadcrumb({
            'Home': '',
            [site.name]: '/site/' + site.siteId,
            'Admin': '/admin/' + site.siteId,
            'Settings': '',
            'Navigation': '/admin/' + site.siteId + '/settings/navigation',
        });
    }, [siteLoading, site, setBreadcrumb]);

    return <h1>Site Settings - Navigation</h1>;
};

export default AdminSettingsNavigation;