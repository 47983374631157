import React, { useCallback, useState } from "react";
import { Dropdown, Modal, Select } from 'antd';
import {
    DeleteOutlined,
    QuestionCircleOutlined,
    ZoomInOutlined,
    CloudDownloadOutlined,
    EditOutlined,
    ArrowsAltOutlined,
    PlusCircleOutlined,
    BugOutlined,
} from '@ant-design/icons';
import { apiGetWithToken, getApiUrl } from "lib/api";
import { Rename } from "./Rename";


export function ContextMenu({ file, children, viewFileDetails, previewFile, refresh }) {
    const [modal, contextHolder] = Modal.useModal();
    const [isRenameOpen, setIsRenameOpen] = useState(false);

    const getMenuItems = useCallback(() => {
        async function addAiBundle() {
            let options = [];
            apiGetWithToken(getApiUrl(`/api/site/${file.siteId}/bundles`),
                null,
                (data) => {
                    //console.log('bundles', data);
                    data.bundles.forEach((b) => {
                        options.push(
                            { value: b.bundleId, label: b.name }
                        );
                    });
                    //                    console.log('Popup');

                    let bundleId = 0;
                    modal.confirm({
                        title: 'Add AI Bundle',
                        centered: true,
                        icon: <PlusCircleOutlined />,
                        content: (
                            <>
                                Choose the bundle you want to apply:
                                <Select
                                    style={{ width: '300px' }}
                                    onChange={(selectedBundleId) => { bundleId = selectedBundleId; }}
                                    options={options}
                                />
                            </>
                        ),
                        onOk: () => {
                            if (bundleId === 0) {
                                return;
                            }

                            console.log('Adding bundle ' + bundleId + ' to folder');

                            apiGetWithToken(getApiUrl(`/api/site/${file.siteId}/file/${file.tableItemId}/addbundle/${bundleId}`),
                                null,
                                (data) => { },
                                (err) => { },
                            );
                        },
                    });
                },
                (err) => { console.error(err); },
            );
        }

        // async function resyncRemoteFolder() {
        //     apiGetWithToken(getApiUrl(`/api/site/${file.siteId}/file/${file.tableItemId}/resync`),
        //         null,
        //         (data) => {
        //             console.log(data);
        //             messageApi.info('Queued the remote folder for re-syncronization');
        //         },
        //         (err) => {
        //             if (err.response.status === 406) {
        //                 messageApi.warning('Please wait before queuing another re-syncronization');
        //             } else {
        //                 messageApi.error('An unknown error occurred');
        //                 console.log(err);
        //             }
        //         },
        //     );
        // }

        function getFolderMenuItems() {
            if (file.name === '<prev folder>') {
                return [];
            }

            // if (file.rootFolderId === 0) {
            //     if (file.storageLocation && file.storageLocation.length) {
            //         if (file.isSyncing !== true) {
            //             return [
            //                 {
            //                     label: 'Resync Remote Folder',
            //                     key: '1',
            //                     icon: <SyncOutlined />,
            //                     onClick: resyncRemoteFolder,
            //                 },
            //             ];
            //         }
            //     }
            //     return [];
            // }

            let options = [];
            let ndx = 0;

            if (!file.remoteFileId) {
                options.push(
                    {
                        label: 'Rename',
                        key: ndx++,
                        icon: <EditOutlined />,
                        onClick: () => { setIsRenameOpen(true) },
                    },
                );
                options.push(
                    {
                        label: 'Move',
                        key: ndx++,
                        icon: <ArrowsAltOutlined />,
                    },
                );
            }

            options.push(
                {
                    label: 'Add AI Bundle',
                    key: ndx++,
                    icon: <PlusCircleOutlined />,
                    onClick: addAiBundle,
                },
            );

            if (!file.remoteFileId) {
                options.push(
                    {
                        type: 'divider',
                    },
                    {
                        label: 'Delete Folder',
                        key: ndx++,
                        icon: <DeleteOutlined />,
                        danger: true,
                    },
                );
            }


            return options;
        }

        function getFileMenuItems() {
            let options = [];
            let ndx = 1;

            options.push(
                {
                    label: 'View File Details',
                    key: ndx++,
                    icon: <QuestionCircleOutlined />,
                    onClick: () => { viewFileDetails(file); },
                },
                {
                    label: 'Preview File',
                    key: ndx++,
                    icon: <ZoomInOutlined />,
                    onClick: () => { previewFile(file); },
                },
                {
                    label: 'Debug AI',
                    key: ndx++,
                    icon: <BugOutlined />,
                    onClick: () => { window.open(getApiUrl(`/api/site/${file.siteId}/file/${file.tableItemId}/debug`)); },
                },
                {
                    type: 'divider',
                },
            );

            if (!file.remoteFileId) {
                options.push(
                    {
                        label: 'Rename',
                        key: ndx++,
                        icon: <EditOutlined />,
                        onClick: () => { setIsRenameOpen(true) },
                    },
                );
                options.push(
                    {
                        label: 'Move',
                        key: ndx++,
                        icon: <ArrowsAltOutlined />,
                    },
                );
            }


            if (!file.remoteFileId) {
                options.push(
                    {
                        label: 'Download File',
                        key: ndx++,
                        icon: <CloudDownloadOutlined />
                    },
                );
            }


            if (!file.remoteFileId) {
                options.push(
                    {
                        type: 'divider',
                    },
                    {
                        label: 'Delete File',
                        key: ndx++,
                        icon: <DeleteOutlined />,
                        danger: true,
                    },
                );
            }

            return options;
        }

        if (!file) {
            return [];
        } else if (file.isFolder) {
            return getFolderMenuItems();
        } else {
            return getFileMenuItems();
        }

    }, [file, modal, viewFileDetails, previewFile]);

    const items = getMenuItems();
    if (items.length === 0) {
        return <></>;
    }

    //console.log('Menu', items);

    return (
        <>
            <Rename file={file} open={isRenameOpen} setOpen={setIsRenameOpen} refresh={refresh} />
            <Dropdown trigger={['click']} menu={{ items }} placement="bottomRight" overlayStyle={{ border: '1px solid #aaa' }}>
                <a onClick={(e) => e.preventDefault()}>
                    <>{children}</>
                </a>
            </Dropdown >
            {contextHolder}
        </>
    );
}
