import axios from 'axios';
import { getCookie } from 'lib/cookies.js';

const DEFAULT_BACKEND_URL = 'https://clearyx-client-portal-be.azurewebsites.net';
const PORTAL_PRIMARY_URL = 'https://clientportal.clearyx.dev';
const PORTAL_REGION_REGEX = /https:\/\/clientportal\.([a-z]{2})\.clearyx\.(dev|legal)/i;
const PORTAL_DEBUG_URL = 'http://localhost';
const PORTAL_LOGIN_PATH = '/login';

export function getBaseUrl() {
    let url = DEFAULT_BACKEND_URL;

    // Infer the backend from the current URL
    const currentLocation = window.location.href;
    if (currentLocation.startsWith(PORTAL_DEBUG_URL)) {
        url = PORTAL_DEBUG_URL + ':8000';
    }
    return url;
}

export function getRegion() {
    const currentLocation = window.location.href;
    // Primary web site.
    if (currentLocation.startsWith(PORTAL_PRIMARY_URL)) {
        return '';
    }
    // Region-locked version, eg. https://clientportal.us.clearyx.dev
    const m = currentLocation.match(PORTAL_REGION_REGEX);
    if (m !== null && m.length >= 2 && m[1].length === 2) {
        return m[1];
    }
    // Local debug version.
    if (currentLocation.startsWith(PORTAL_DEBUG_URL)) {
        return 'dev';
    }
    return '';
}

export function getRegionName() {
    const region = getRegion();
    switch (region) {
        case '':
            return 'Global';
        case 'dev':
            return 'Local';
        case 'ca':
            return 'Canada';
        case 'us':
            return 'United States';
        case 'de':
            return 'Germany';
        default:
            return '';
    }
}

export function getApiUrl(path) {
    let url = getBaseUrl();
    if (path.length > 0) {
        if (path.charAt(0) !== '/') {
            url = url + '/';
        }
        url = url + path;
    }
    return url;
}

export function getAuthorizationHeader() {
    const token = getCookie('token');
    return 'Bearer ' + token;
}

export async function apiGetWithToken(url, preFunc = null, okFunc = null, errFunc = null, postFunc = null, progressFunc = null, abortSignal = null, responseType = 'json') {
    let lastProgress = '';

    if (preFunc !== null) {
        preFunc();
    }

    let controller = null;
    if (abortSignal === null) {
        controller = new AbortController();
        abortSignal = controller.signal;
    }

    // console.debug('API Call', url);
    // console.debug('Token', token);
    let cancelled = false;
    const ok = await axios.get(url, {
        signal: abortSignal,
        responseType: responseType,
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': getAuthorizationHeader(),
        },
        onDownloadProgress: (e) => {
            if (progressFunc !== null) {
                // console.log(e);
                const progress = e.event.currentTarget.response;
                progressFunc(progress, progress.substring(lastProgress.length).trim());
                lastProgress = progress;
            }
        }
    })
        .then(function (response) {
            // console.log('RESP', response);
            if (response.status === 200) {
                if (okFunc !== null) {
                    okFunc(response.data);
                }
            }
            return true;
        })
        .catch(function (error) {
            if (axios.isCancel(error)) {
                cancelled = true;
                // console.debug('Api call cancelled');
            } else {
                console.log('ERR', error);
                if (errFunc !== null) {
                    errFunc(error);
                }
            }
            return true;
        });
    if (postFunc !== null) {
        postFunc(cancelled);
    }

    return controller === null ? ok : controller;
}

export async function apiPostWithToken(url, values, preFunc = null, okFunc = null, errFunc = null, postFunc = null) {
    if (preFunc !== null) {
        preFunc();
    }

    //console.log('Dashboard contacting', url);
    const token = getCookie('token');

    //console.debug('API Call', url);
    const res = await axios.post(url, values, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token,
        }
    })
        .then(function (response) {
            // console.debug('RESP', response);
            if (response.status === 200) {
                return okFunc(response.data);
            }
            return true;
        })
        .catch(function (err) {
            console.debug('ERR', err);
            let msg = 'Unknown error';
            if (err.response && err.response.data && err.response.data.message) {
                msg = err.response.data.message;
            } else if (err.message) {
                msg = err.message;
            }
            return errFunc(msg);
        });
    if (postFunc !== null) {
        postFunc();
    }
    return res;
}

export function requireSiteId(siteId) {
    if (isNaN(siteId) || siteId === 0) {
        console.error('No siteId passed to component');
        window.location.href = PORTAL_LOGIN_PATH;
        return false;
    }
    return true;
}

export function redirectToLogin(err) {
    window.location.href = PORTAL_LOGIN_PATH;
    return;
}

// Sometimes we get multiple JSON chunks together, need to account for that.
export function parseJsonChunk(chunk, callback) {
    chunk = chunk.trim();
    // console.log('CHUNK', chunk);
    let bracketCount = 0;
    let start = 0;
    for (let i = 0; i < chunk.length; i++) {
        if (chunk[i] === '{') {
            bracketCount++;
        } else if (chunk[i] === '}') {
            bracketCount--;
        }
        if (bracketCount === 0) {
            const piece = chunk.substr(start, i - start + 1).trim();
            start = i + 1;
            if (piece.length) {
                // console.log('piece', piece);
                try {
                    const obj = JSON.parse(piece);
                    callback(obj);
                } catch (e) {
                    console.error('Invalid JSON: ' + piece)
                }
            }
        }
    }
}

export function generateQueryUrl(siteId, viewId, tableId, primary, secondary, filter, limit = 0) {
    const url = new URL(getApiUrl(`/api/site/${siteId}/query`));
    viewId && url.searchParams.append('viewId', viewId);
    tableId && url.searchParams.append('tableId', tableId);
    primary && url.searchParams.append('primary', primary);
    secondary && url.searchParams.append('secondary', secondary);
    filter && url.searchParams.append('filter', filter);
    limit && url.searchParams.append('limit', limit);
    return url;
}
