import React, { useEffect } from "react";
import { useOutletContext } from "react-router-dom";

const AdminSettingsModules = () => {
    const { site, siteLoading, setBreadcrumb } = useOutletContext();

    useEffect(() => {
        if (siteLoading) {
            return;
        }
        setBreadcrumb({
            'Home': '',
            [site.name]: '/site/' + site.siteId,
            'Admin': '/admin/' + site.siteId,
            'Settings': '',
            'Modules': '/admin/' + site.siteId + '/settings/modules',
        });
    }, [siteLoading, site, setBreadcrumb]);

    return <h1>Site Settings - Modules</h1>;
};

export default AdminSettingsModules;