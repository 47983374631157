import React, { useState, useEffect } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { getApiUrl, apiGetWithToken, requireSiteId, redirectToLogin } from 'lib/api';
import CXLoading from "components/CXLoading";

const AdminViewPage = () => {
    const pageId = parseInt(useParams().pageId);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState({});
    const { site, siteLoading, setBreadcrumb } = useOutletContext();

    useEffect(() => {
        if (siteLoading) {
            return;
        }
        setBreadcrumb({
            'Home': '',
            [site.name]: '/site/' + site.siteId,
            'Admin': '/admin/' + site.siteId,
            'Pages': '/admin/' + site.siteId + '/pages',
            'This Page': '',
        });

        if (!requireSiteId(site.siteId)) {
            return;
        }
        const url = getApiUrl('/api/site/' + site.siteId + '/page/' + pageId);
        apiGetWithToken(url,
            () => { setLoading(true); },
            (data) => { setPage(data.page); },
            (err) => { redirectToLogin(err); },
            () => { setLoading(false); }
        );

    }, [siteLoading, site, setBreadcrumb, pageId]);

    if (loading || siteLoading) {
        return <CXLoading text="Loading page..." />
    }

    return (
        <>
            <h1>Page: {page.title}</h1>
            <div><b>Author: </b> {page.createdByUser.name}</div>
            <hr />
            <div>{page.content}</div>
        </>
    );
};

export default AdminViewPage;