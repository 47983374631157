import React, { useEffect } from "react";
import { Flex } from 'antd';

export const CXTableWrapperHeader = ({ children }) => {
    return (
        <>
            {children}
        </>
    );
}

export const CXTableWrapperFooter = ({ children }) => {
    return (
        <>
            {children}
        </>
    );
}


const CXTableWrapper = ({ children, style, setyHeight }) => {

    useEffect(() => {
        function handleResize() {
            const el = document.querySelector('#tableWrapperOuter');
            const el2 = document.querySelector('#tableWrapperHeader');
            // console.log(el);
            // console.log(el2);
            // const elHeight = el ? el.offsetHeight : -1;
            // const elHeight2 = el2 ? el2.offsetHeight : -1;
            // console.log(elHeight);
            // console.log(elHeight2);
            const headerHeight = 50 + 2; // Need to calculate
            const yh = (el && el2) ? (el.offsetHeight - el2.offsetHeight - headerHeight) : 200;
            setyHeight && setyHeight(yh);
            // console.log('Setting y to ' + yh);
        };

        // Introduce a small delay in the handler for rapid re-sizing.
        window.addEventListener('resize', () => { setTimeout(handleResize, 200); });
        handleResize();

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [setyHeight]);

    let header = <></>;
    let footer = <></>;
    let content = [];
    children.forEach((child) => {
        if (!React.isValidElement(child)) return;
        if (child.type.name === 'CXTableWrapperHeader' || child.type === CXTableWrapperHeader) {
            header = child;
        } else if (child.type.name === 'CXTableWrapperFooter' || child.type === CXTableWrapperFooter) {
            footer = child;
        } else {
            content.push(child);
        }
    });

    return (
        <Flex id="tableWrapperOuter" vertical style={{ padding: 0, borderRadius: '8px', ...style }} >
            <Flex id="tableWrapperHeader" vertical style={{ padding: '10px', minWidth: '250px', backgroundColor: '#b6b8de', borderRadius: '8px 8px 0px 0px' }}>
                {header}
            </Flex>

            <Flex id="tableWrapperContent" vertical style={{ padding: 0, borderRadius: '0px' }} >
                {content}
            </Flex>

            <Flex id="tableWrapperFooter">
                {footer}
            </Flex>
        </Flex >
    );
}

CXTableWrapper.Header = CXTableWrapperHeader;
CXTableWrapper.Footer = CXTableWrapperFooter;

export default CXTableWrapper;