import React, { useState, useEffect, useRef } from "react";
import { Modal, Form, Input, message } from 'antd';
import { getApiUrl, apiPostWithToken } from 'lib/api';

export const Rename = ({ open, setOpen, file, refresh }) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [enabled, setEnabled] = useState(true);
    const [form] = Form.useForm();

    // Clear the form when opened or closed.
    useEffect(() => {
        if (open) {
            form.setFieldValue('name', file.name);
            setTimeout(() => { form.getFieldInstance('name').focus(); }, 100);
        }
        return () => {
            form.setFieldValue('name', '');
        }
    }, [open, form, file]);


    async function onRename() {
        const values = { name: form.getFieldValue('name') };
        const url = getApiUrl(`/api/site/${file.siteId}/file/${file.tableItemId}/rename`);
        file.name = form.getFieldValue('name');
        apiPostWithToken(url, values,
            () => { setEnabled(false); },
            (data) => {
                // console.log(data);
                if (data.code === 200) {
                    refresh();
                    messageApi.info((file.isFolder ? 'Folder' : 'File') + ' renamed.');
                    setOpen(false);
                } else {
                    messageApi.error(data.message);
                }
            },
            (err) => { messageApi.error(err); },
            () => { setEnabled(true); }
        );

        return false;
    }

    const title = "Rename " + (file.isFolder ? "Folder" : "File");
    const label = "New " + (file.isFolder ? "Folder" : "File") + " Name:"
    const tooltip = "The new name for this " + (file.isFolder ? "folder" : "file");

    return (
        <>
            {contextHolder}
            <Modal
                title={title}
                centered
                closable
                keyboard
                open={open}
                width="800px"
                okText="Rename"
                cancelText="Cancel"
                onCancel={() => { setOpen(false); }}
                onOk={form.submit}
                okButtonProps={{ disabled: !enabled }}
                cancelButtonProps={{ disabled: !enabled }}
            >
                <Form form={form} layout="vertical" disabled={!enabled} onFinish={onRename}>
                    <br />
                    <Form.Item name="name" label={label} rules={[{ required: true }]} tooltip={tooltip}>
                        <Input />
                    </Form.Item>
                </Form >

            </Modal >
        </>
    );
};
