import React, { useRef } from 'react';
import { getBaseUrl } from 'lib/api';

const CXLogo = ({ siteId, site, noDefault, dark }) => {
  const ref = useRef();
  const imagePath = getBaseUrl() + '/api/site/' + siteId + '/logo';
  // TODO - we might need a light and dark version of the CORA logo
  // const clearyPath = dark ? '/static/ClearyX-logo-dark.png' : '/static/ClearyX-logo.png';
  const clearyPath = '/static/CORA-Logo.png';

  function handleFallback() {
    ref.current.onError = null;
    if (noDefault !== true) {
      ref.current.src = clearyPath;
    }
  }

  if (!siteId && !site) {
    return (<></>);
  }

  let path = imagePath;
  if (site && !site.hasLogo && noDefault !== true) {
    path = clearyPath;
  } else if (noDefault) {
    path += '?noDefault=1';
  }

  return (
    <div style={{ height: '50px', minHeight: '50px' }}>
      <img
        alt="logo"
        src={path}
        ref={ref}
        onError={handleFallback}
        style={{ maxHeight: '50px', maxWidth: '160px', padding: '10px' }}></img >
    </div>
  )
};

export default CXLogo;