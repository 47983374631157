import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Tabs, Rate, Flex, Button, List } from 'antd';
import { getApiUrl, apiGetWithToken } from 'lib/api';
import { ReloadOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { DashboardNewSite } from "./NewSite";

export const DashboardSites = ({ user, userLoading }) => {
    const [sites, setSites] = useState([]);
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [siteFilter, setSiteFilter] = useState('');


    useEffect(() => {
        const url = getApiUrl('/api/sites?filter=' + (siteFilter) + (refresh > 0 ? '&refresh=1' : ''));
        apiGetWithToken(url,
            () => { setLoading(true); },
            (data) => { setSites(data.sites); },
            (err) => { window.location.href = "/login"; },
            () => { setLoading(false); }
        );
    }, [refresh, userLoading, siteFilter]);

    async function onClickRefresh() {
        setSites([]);
        setRefresh(refresh + 1);
        return false;
    }

    async function onClickAddSite() {
        setModalOpen(true);
        return false;
    }

    async function onClickStar(value) {
    }

    const newSiteButton = (user && user.superUser) ?
        (<Button type="primary" icon={<PlusCircleOutlined />} onClick={onClickAddSite}>Add Site</Button>) :
        (<></>);

    let tabs = [
        { label: 'My Sites', key: "my" },
        { label: 'Favorites', key: "fav" },
    ];
    if (user && user.superUser) {
        tabs.push({ label: 'All Sites', key: "all" });
    }

    function onTabClick(key) {
        setRefresh(refresh + 1);
        setSiteFilter(key);
    }

    return (
        <>
            <DashboardNewSite modalOpen={modalOpen} setModalOpen={setModalOpen} />
            <Flex id="sitelist" vertical style={{ minWidth: '400px' }}>
                <Flex gap="small" style={{ paddingBottom: '10px' }}>
                    <Button icon={<ReloadOutlined />} onClick={onClickRefresh}>Refresh</Button>
                    {newSiteButton}
                </Flex>
                <Flex>
                    <Tabs
                        defaultActiveKey="1"
                        size='small'
                        items={tabs}
                        onTabClick={onTabClick}
                    />
                </Flex>
                <List
                    // header={<div><b>Sites</b></div>}
                    // footer={<div><b>Footer</b></div>}
                    bordered
                    dataSource={sites}
                    loading={loading}
                    renderItem={(item, index) => {
                        return (
                            <List.Item>
                                <Flex gap="small">
                                    <Flex>
                                        <Rate allowClear count="1" onChange={onClickStar}></Rate>
                                    </Flex>
                                    <Flex>
                                        <Link to={{
                                            pathname: item.homePageId ? `/site/${item.siteId}/page/${item.homePageId}` : `/site/${item.siteId}`,
                                            state: { item },
                                        }}>
                                            {item.name}
                                        </Link>
                                    </Flex>
                                </Flex>
                            </List.Item >
                        );
                    }}
                />
            </Flex>
        </>
    );
};
