import React, { useEffect, useState } from 'react';
import { Card, Table } from 'antd';
import CXLoading from "components/CXLoading";
import { apiGetWithToken, generateQueryUrl } from 'lib/api';

const CXDatachart = ({ title, siteId, tableId, viewId, primary, secondary, filter, primaryLabel, secondaryLabels, limit, verboseLogging, setApiUrl, messageApi }) => {
  const [loading, setLoading] = useState(true);
  const [table, setTable] = useState([]);
  const [xAxisKey, setxAxisKey] = useState('');
  const [xAxisName, setxAxisName] = useState('');
  const [yAxisKeys, setyAxisKeys] = useState([]);
  const [yAxisNames, setyAxisNames] = useState([]);

  useEffect(() => {
    if (!loading) {
      verboseLogging && console.log('Not loading');
      return;
    }
    if (!siteId) {
      verboseLogging && console.log('No siteId');
      return;
    }
    if (!tableId && !viewId) {
      verboseLogging && console.log('No tableId or viewId');
      return;
    }

    const url = generateQueryUrl(siteId, viewId, tableId, primary, secondary, filter, limit);
    setApiUrl && setApiUrl(url.toString());
    apiGetWithToken(url.toString(),
      () => { setLoading(true); },
      (data) => {
        let yKeys = [];
        let yNames = [];
        for (let i = 0; i < data.view.fieldList.length; i++) {
          if (i === 0) {
            setxAxisKey(data.view.fieldList[i].internalName);
            setxAxisName(data.view.fieldList[i].name);
          } else {
            yKeys.push(data.view.fieldList[i].internalName);
            yNames.push(data.view.fieldList[i].name);
          }
        }
        setyAxisKeys(yKeys);
        setyAxisNames(yNames);
        setTable(data.view.fieldValues);
      },
      (err) => {
        console.log(err);
        messageApi && messageApi.error(err.message);
      },
      () => { setLoading(false); }
    );

  }, [loading, siteId, tableId, viewId, filter, primary, secondary, limit, setApiUrl, verboseLogging, messageApi]);

  const xAxisLabel = primaryLabel ? primaryLabel : xAxisName;
  let yAxisLabels = yAxisNames;
  if (secondaryLabels) {
    const l = secondaryLabels.split(',');
    for (let i = 0; i < l.length; i++) {
      yAxisLabels[i] = l[i];
    }
  }

  const columns = [
    {
      title: xAxisLabel,
      dataIndex: xAxisKey,
      //key: 'name',
      // render: (text) => <a>{text}</a>,
    },

    {
      title: yAxisLabels[0],
      dataIndex: yAxisKeys[0],
      // key: 'age',
    },
  ];


  const Inside = () => {
    if (loading) {
      return <CXLoading text="Loading graph..." />
    }

    return (
      <Table
        columns={columns}
        dataSource={table}
      />
    );
  }

  if (!title) {
    return <Inside />;
  }

  return (
    <>
      <Card title={title}>
        <Inside />
      </Card>
    </>
  );
};

export default CXDatachart;