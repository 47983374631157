import React, { useState, useEffect } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { getApiUrl, apiGetWithToken, redirectToLogin } from 'lib/api';
import { List } from 'antd';
import CXLoading from "components/CXLoading";

const AdminPages = () => {
    //    const siteId = parseInt(useParams().siteId);
    const [loading, setLoading] = useState(true);
    const [pages, setPages] = useState([]);
    const { site, siteLoading, setBreadcrumb } = useOutletContext();

    useEffect(() => {
        if (siteLoading) {
            return;
        }
        setBreadcrumb({
            'Home': '',
            [site.name]: '/site/' + site.siteId,
            'Admin': '/admin/' + site.siteId,
            'List All Pages': '/admin/' + site.siteId + '/pages',
        });

        const url = getApiUrl('/api/site/' + site.siteId + '/pages');
        apiGetWithToken(url,
            () => { setLoading(true); },
            (data) => { console.log(data); setPages(data.pages); },
            (err) => { redirectToLogin(err); },
            () => { setLoading(false); }
        );
    }, [site, siteLoading, setBreadcrumb]);

    if (loading || siteLoading) {
        return <CXLoading text="Loading pages..." />
    }

    return (
        <>
            <h1>{site.name} Pages</h1>

            <List
                header={<div><b>Pages</b></div>}
                dataSource={pages}
                bordered
                size="large"
                renderItem={(item) => (
                    <List.Item>
                        <div>#{item.pageId} - {item.title}</div>
                        <div>Author: {item.createdByUser.name} ({item.createdByUser.email})</div>
                        <Link to={{
                            pathname: `/site/${site.siteId}/page/${item.pageId}`,
                            state: { item },
                        }}>
                            View
                        </Link>
                        &nbsp;|&nbsp;
                        <Link to={{
                            pathname: `/site/${site.siteId}/page/${item.pageId}/edit`,
                            state: { item },
                        }}>
                            Edit
                        </Link>
                    </List.Item >
                )}
            />
        </>
    );
};

export default AdminPages;