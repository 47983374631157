import React from 'react';
import { Dropdown, Flex } from 'antd';
import { CaretDownFilled, SelectOutlined } from '@ant-design/icons';

const CXSiteSelector = ({ site, user, includeCurrentUser }) => {

  function getItems() {
    let items = [];
    // console.log(user.roles);
    for (const x in user.roles) {
      // console.log(x, user.roles[x]);
      let o = {
        key: x,
        icon: <SelectOutlined />,
        label: (
          <>
            <div style={{ border: '1px solid #999', padding: '10px', backgroundColor: '#ddd' }} onClick={(v) => {
              window.location.href = `/site/${user.roles[x].siteId}`;
            }}>
              <div>
                <b>{user.roles[x].siteName}</b>
              </div>
              <div>
                Site ID: {user.roles[x].siteId}
              </div>
            </div>
          </>
        )
      };
      items.push(o);
    }
    return items;
  }

  if (!user || !site) {
    return <></>;
  }

  const items = getItems();

  const currentUser = includeCurrentUser ?
    <div>{user.name}</div> :
    <></>;

  return (
    <Dropdown trigger={['click']} menu={{ items }} >
      <Flex style={{ cursor: 'pointer' }}>
        <Flex vertical>
          <div><b>{site.name}</b></div>
          {currentUser}
        </Flex>
        <Flex style={{ width: '8px' }}>
          &nbsp;
        </Flex>
        <Flex>
          <div>
            <CaretDownFilled />
          </div>
        </Flex>

      </Flex >
    </Dropdown >
  );

};

export default CXSiteSelector;