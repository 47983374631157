import React, { useEffect } from "react";
import { useOutletContext, useNavigate } from "react-router-dom";
import CXLoading from "components/CXLoading";


const Site = () => {
    const { site, siteLoading } = useOutletContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (siteLoading) {
            return;
        }
        if (site.homePageId) {
            const url = `/site/${site.siteId}/page/${site.homePageId}`;
            console.log('Navigating to home page at ' + url)
            navigate(url);
        }
    }, [site, siteLoading, navigate]);

    if (siteLoading) {
        return <CXLoading text="Loading site..." />
    }

    return (
        <>
            <h1>{site.name}</h1>
            This is where the home page would be, if you had one.
        </>
    );

};

export default Site;