import React, { useEffect, useState } from "react";
import { Modal, Form, Input, message, Button, Flex } from 'antd';
import { getApiUrl, apiPostWithToken, apiGetWithToken } from 'lib/api';

const { TextArea } = Input;

export const EditBundle = ({ open, setOpen, bundle, setBundle, site, setRefresh }) => {
    const [enabled, setEnabled] = useState(true);
    const [messageApi, contextHolder] = message.useMessage();
    const [modal, modalContextHolder] = Modal.useModal();
    const [form] = Form.useForm();

    function onClickDelete() {
        modal.confirm({
            title: 'Confirm Bundle Deletion',
            centered: true,
            content: (
                <>
                    <Flex vertical gap="small">
                        <Flex>
                            <b>Are you absolutely sure you want to delete this question bundle?</b>
                        </Flex>
                        <Flex>This will also delete any questions
                            inside it, as well as any answers that were previously generated for those questions.
                        </Flex>
                    </Flex>
                </>
            ),
            onOk: () => {
                apiGetWithToken(getApiUrl(`/api/site/${site.siteId}/bundle/${bundle.bundleId}/delete`),
                    null,
                    (data) => { setOpen(false); setRefresh(true); },
                    (err) => { messageApi.error('An unknown error occurred deleting the bundle, please try again.') },
                );
            },
        });
    }

    function onCreate() {
        form.validateFields().then((values) => {
            if (bundle) {
                values['bundleId'] = bundle.bundleId;
            }
            console.log(values);
            apiPostWithToken(getApiUrl(`/api/site/${site.siteId}/bundle`), values,
                () => { setEnabled(false); },
                (data) => {
                    form.resetFields();
                    setOpen(false);
                    setRefresh(true);
                },
                (err) => { messageApi.error('An unknown error occurred, please try again.'); },
                () => { setEnabled(true); },
            );
        }).catch((info) => {
            console.log("Validate Failed:", info);
        });
    }

    useEffect(() => {
        // console.log('form/bundle change', bundle);
        form.setFieldValue('name', bundle ? bundle.name : '');
        form.setFieldValue('description', bundle ? bundle.description : '');
        form.setFieldValue('instructions', bundle ? bundle.instructions : '');
    }, [form, bundle]);

    return (
        <>
            <Modal
                title={bundle ? "Edit Bundle" : "Add New Bundle"}
                destroyOnClose
                centered
                open={open}
                width="600px"
                closable={false}
                footer={[
                    <Flex key="1" style={{ paddingTop: '20px' }}>
                        <Flex flex="1">
                            <Button onClick={onClickDelete} style={{ display: bundle ? 'inline' : 'none' }} danger>Delete</Button>
                        </Flex>
                        <Flex gap="small">
                            <Button onClick={() => { setOpen(false); }} disabled={!enabled}>Cancel</Button>
                            <Button onClick={onCreate} disabled={!enabled} type="primary">{bundle ? "Save" : "Create"} Bundle</Button>
                        </Flex>
                    </Flex>
                ]}
            >
                <br />

                <Form
                    form={form}
                    layout="vertical"
                    disabled={!enabled}
                    labelWrap="true"
                // initialValues={bundle}
                >
                    <Form.Item name="name" label="Bundle Name" rules={[{ required: true }]} tooltip="The name of the bundle">
                        <Input />
                    </Form.Item>

                    <Form.Item name="description" label="Description" rules={[{ required: true }]} tooltip="A full description of the bundle.">
                        <TextArea rows={3} />
                    </Form.Item>

                    <Form.Item name="instructions" label="Additional Instructions" tooltip="Any additional instructions that you wish to pass through the ClearyAI.  For example, you could specify that the set of questions is specifically related to contracts, or NDAs.">
                        <TextArea rows={4} />
                    </Form.Item>
                </Form >
            </Modal >

            {modalContextHolder}
            {contextHolder}
        </>
    );
};
