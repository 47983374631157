import { BrowserRouter, Routes, Route } from "react-router-dom";

import Theme from "./Theme";

import Admin from "./pages/admin/Admin";
import AdminLayout from "./pages/admin/AdminLayout";
import AdminPages from "./pages/admin/pages/AdminPages";
import AdminViewPage from "./pages/admin/pages/AdminViewPage";
import AdminTables from "./pages/admin/tables/AdminTables";
import AdminUsers from "./pages/admin/users/AdminUsers";
import AdminGroups from "./pages/admin/groups/AdminGroups";
import AdminRoles from "./pages/admin/roles/AdminRoles";
import AdminQuestions from "pages/admin/questions/AdminQuestions";
import AdminSettingsGeneral from "./pages/admin/settings/AdminSettingsGeneral";
import AdminSettingsModules from "./pages/admin/settings/AdminSettingsModules";
import AdminSettingsNavigation from "./pages/admin/settings/AdminSettingsNavigation";
import AdminSettingsSecurity from "./pages/admin/settings/AdminSettingsSecurity";

import PageLayout from "./pages/site/Layout";
import Site from "./pages/site/Site";
import Page from "pages/site/page/Page";
import EditPage from "pages/site/page/EditPage";
import ViewTable from "pages/site/table/ViewTable";
import ListFiles from "pages/site/files/ListFiles";

import Home from "./pages/home/Home";
import Dashboard from "./pages/dashboard/Dashboard";
import LoginPage from "./pages/login/LoginPage";
import PageNotFound from "./pages/404/PageNotFound";
import AdminBundles from "pages/admin/questions/AdminBundles";
import GraphExplorer from "pages/admin/graphs/GraphExplorer";

export default function App() {
  return (
    <BrowserRouter>
      <Theme>
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
            <Route path="static/*" />
            <Route path="env.js" />
            <Route path="robots.txt" />

            <Route path="dashboard">
              <Route index element={<Dashboard />} />
            </Route>

            <Route path="admin" element={<AdminLayout />} >
              <Route path=":siteId" element={<Admin />} />
              <Route path=":siteId/settings/general" element={<AdminSettingsGeneral />} />
              <Route path=":siteId/settings/modules" element={<AdminSettingsModules />} />
              <Route path=":siteId/settings/navigation" element={<AdminSettingsNavigation />} />
              <Route path=":siteId/settings/security" element={<AdminSettingsSecurity />} />
              <Route path=":siteId/pages" element={<AdminPages />} />
              <Route path=":siteId/pages/create" element={<EditPage create />} />
              <Route path=":siteId/pages/:pageId" element={<AdminViewPage />} />
              <Route path=":siteId/tables" element={<AdminTables />} />
              <Route path=":siteId/users" element={<AdminUsers />} />
              <Route path=":siteId/groups" element={<AdminGroups />} />
              <Route path=":siteId/roles" element={<AdminRoles />} />
              <Route path=":siteId/graphs" element={<GraphExplorer />} />
              <Route path=":siteId/bundles" element={<AdminBundles />} />
              <Route path=":siteId/bundle/:bundleId" element={<AdminQuestions />} />
            </Route>

            <Route path="site" element={<PageLayout />}>
              <Route path=":siteId" element={<Site />} />
              <Route path=":siteId/page/:pageId" element={<Page />} />
              <Route path=":siteId/page/:pageId/view" element={<Page />} />
              <Route path=":siteId/page/:pageId/edit" element={<EditPage />} />

              <Route path=":siteId/table/:tableId" element={<ViewTable />} />
              <Route path=":siteId/view/:viewId" element={<ViewTable />} />

              <Route path=":siteId/files" element={<ListFiles />} />
              <Route path=":siteId/files/:folderId" element={<ListFiles />} />
            </Route>

            <Route path="login" element={<LoginPage />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </Theme>
    </BrowserRouter >
  );
}


// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(<App />);