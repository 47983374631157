import React from 'react';
import { Link } from "react-router-dom";
import { Breadcrumb, Skeleton } from 'antd';

const CXBreadcrumb = ({ breadcrumb }) => {

  function parseBreadcrumb(b) {
    let items = [];

    for (const [key, value] of Object.entries(b)) {
      items.push({
        title: key,
        path: value,
      });
    }

    return items;
  }

  function itemRender(route) {
    if (route.path === '') {
      return <span>{route.title}</span>;
    } else {
      return <span><Link to={route.path}>{route.title}</Link></span>;
    }
  }

  const items = parseBreadcrumb(breadcrumb);

  if (items.length === 0) {
    return (
      <>
        <Skeleton title={false} paragraph={{ rows: 1 }} active />
      </>
    );
  }

  return (
    <Breadcrumb
      //style={{ padding: '0px', lineHeight: '1em', fontSize: '1.1em' }}
      separator=">"
      itemRender={itemRender}
      items={items}
    />
  );
};

export default CXBreadcrumb;