import React, { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { Statistic, Card, Flex } from "antd";
import CXLoading from "components/CXLoading";





const Admin = () => {
    const { site, user, users, groups, roles, siteLoading, setBreadcrumb } = useOutletContext();

    useEffect(() => {
        if (siteLoading) {
            return;
        }
        setBreadcrumb({
            'Home': '',
            [site.name]: '/site/' + site.siteId,
            'Admin': '/admin/' + site.siteId,
        });
    }, [site, user, siteLoading, setBreadcrumb]);

    if (siteLoading) {
        return <CXLoading text="Loading site..." />
    }

    return (
        <>
            <h1>Admin</h1>
            <Flex>
                <Card style={{ width: '150px' }}>
                    <Statistic title="Active Users" value={users.length} />
                </Card>
                <Card style={{ width: '150px' }}>
                    <Statistic title="Groups" value={groups.length} />
                </Card>
                <Card style={{ width: '150px' }}>
                    <Statistic title="Roles" value={roles.length} />
                </Card>
            </Flex>
        </>
    );
};

export default Admin;