import React, { useState } from "react";
import { Modal, Form, Input, Flex, Button, message } from 'antd';
import { getApiUrl, apiPostWithToken, redirectToLogin } from 'lib/api';

const { TextArea } = Input;

export const DashboardNewSite = ({ modalOpen, setModalOpen }) => {
    const [enabled, setEnabled] = useState(true);
    const [messageApi, contextHolder] = message.useMessage();

    async function onCreate(values) {
        const url = getApiUrl('/api/site/create');
        console.log(values);
        apiPostWithToken(url, values,
            () => { setEnabled(false); },
            (data) => {
                console.log(data);
                if (data.code === 200) {
                    window.location.href = 'admin/' + data.siteId + '/settings/general';
                } else {
                    messageApi.error(data.message);
                    setEnabled(true);
                }
            },
            (err) => {
                console.log(err);
                redirectToLogin();
            },
            () => { }
        );

        return false;
    }

    return (
        <>
            {contextHolder}
            <Modal
                title="Add New Site"
                centered
                open={modalOpen}
                width="600px"
                okButtonProps={{ style: { display: 'none' } }}
                cancelButtonProps={{ style: { display: 'none' } }}
            >

                <Form
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 14 }}
                    layout="horizontal"
                    onFinish={onCreate}
                    disabled={!enabled}
                    labelWrap="true"
                >

                    <Form.Item name="name" label="Site Name" rules={[{ required: true }]} tooltip="The name of the site">
                        <Input />
                    </Form.Item>

                    <Form.Item name="description" label="Description">
                        <TextArea rows={4} />
                    </Form.Item>

                    <Form.Item style={{ marginBottom: "0px" }} wrapperCol={{ span: 8, offset: 13 }}>
                        <Flex flex="1" gap="middle" justify="end">
                            <Button disabled={!enabled} onClick={() => { setModalOpen(false); }}>
                                Cancel
                            </Button>
                            <Button type="primary" htmlType="submit" disabled={!enabled}>
                                Create Site
                            </Button>
                        </Flex>
                    </Form.Item>

                </Form >

            </Modal >
        </>
    );
};
