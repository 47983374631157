import React, { useState, useEffect, useCallback } from "react";
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { Drawer } from 'antd';
import { getApiUrl, apiGetWithToken, getAuthorizationHeader } from 'lib/api';

const options = {
    // verbosity: pdfjs.VerbosityLevel.INFOS,
    httpHeaders: {
        'Authorization': getAuthorizationHeader(),
    },
    cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
}

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.mjs`;

export const FilePreview = ({ siteId, tableItemId, open, setOpen }) => {
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);


    useEffect(() => {
        if (!open) {
            return;
        }

        // set up

        return () => {
            console.log('Unloading preview window');
            setNumPages(0);
        }
    }, [open]);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }


    function highlightPattern(text, pattern) {
        return text.replace(pattern, (value) => `<mark>${value}</mark>`);
    }

    const searchText = "Salary";
    const textRenderer = useCallback(
        (textItem) => {
            // console.log('textItem', textItem);
            return highlightPattern(textItem.str, searchText)
        },
        []
    );

    function Viewer() {
        if (!siteId || !tableItemId || !open) {
            return <>Loading...</>;
        }

        const fileUrl = getApiUrl(`/api/site/${siteId}/file/${tableItemId}/fetch/${tableItemId}`);
        console.log(fileUrl);
        return (
            <>
                <Document
                    file={fileUrl}
                    options={options}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoadError={console.error}
                    onSourceError={console.error}
                >
                    {Array.from(
                        new Array(numPages),
                        (el, index) => (
                            <>
                                <Page
                                    customTextRenderer={textRenderer}
                                    key={`page_${index + 1}`}
                                    pageNumber={index + 1}
                                    //height="500"
                                    width="900"
                                />
                                <br />
                                <br />
                            </>
                        ),
                    )}
                    {/* <Page pageNumber={pageNumber} /> */}
                </Document >
                <p>
                    Page {pageNumber} of {numPages}
                </p>
            </>
        );
    }

    return (
        <Drawer width={1000} placement="right" title="File Preview" open={open} onClose={() => { setOpen(false); }}>
            {/* <p><b>Name:</b> {file.name}</p>
            <p><b>Internal ID:</b> {file.tableItemId}</p> */}
            <Viewer />
        </Drawer>
    );
};
