import React, { useState, useEffect, useCallback } from "react";
import { Flex, Table, message, Button, Tag } from 'antd';
import {
  UploadOutlined,
  ReloadOutlined,
  FolderAddOutlined,
  CloudDownloadOutlined,
  CommentOutlined,
  DeleteOutlined,
  GlobalOutlined,
  MoreOutlined,
  CloudOutlined,
} from '@ant-design/icons';
import { getApiUrl, apiGetWithToken, parseJsonChunk } from 'lib/api';
import dayjs from 'dayjs';
import CXLoading from "components/CXLoading";
import { UploadDialog } from "./UploadDialog.js";
import { AskClearyAI } from "./AskClearyAI.js";
import { ClickableFile } from "./ClickableFile.js";
import { FileTags } from "./FileTags.js";
import { DeleteFiles } from "./DeleteFiles.js";
import { CreateFolder } from "./CreateFolder.js";
import { DownloadFiles } from "./DownloadFiles.js";
import { FileDrawer } from "./FileDrawer.js";
import { FilePreview } from "./FilePreview.js";
import { ContextMenu } from "./ContextMenu.js";

const CXFileExplorerComponent = ({ site, siteInfo, folderId }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [loadingFolders, setLoadingFolders] = useState(true);
  const [loadingFiles, setLoadingFiles] = useState(true);
  const [files, setFiles] = useState([]);
  const [folders, setFolders] = useState([]);
  const [currentFolderId, setCurrentFolderId] = useState(isNaN(folderId) ? 0 : folderId);
  const [isRemoteFolder, setIsRemoteFolder] = useState({});
  const [remoteFolderType, setRemoteFolderType] = useState('');
  const [reloadFiles, setReloadFiles] = useState(false);
  const [reloadFolders, setReloadFolders] = useState(true);
  const [forceReload, setForceReload] = useState(false);
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isDownloadOpen, setIsDownloadOpen] = useState(false);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({});
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [tableItemIdsForComponent, setTableItemIdsForComponent] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [fileForDrawer, setFileForDrawer] = useState({});
  const [fileForPreview, setFileForPreview] = useState(0);
  const [fileForContextMenu, setFileForContextMenu] = useState({});
  const [openAskClearyAIDrawer, setOpenAskClearyAIDrawer] = useState(false);
  const [tableLastUpdateVersion, setTableLastUpdateVersion] = useState(0);

  // If we've loaded the site, but there's no folderId specified, we're in the root folder.
  useEffect(() => {
    if (site && currentFolderId === 0) {
      console.log('Setting current folder to site root ', site.rootFolderId);
      setCurrentFolderId(site.rootFolderId);
    }

    return () => {
      setFiles([]);
      setIsContextMenuOpen(false);
      setContextMenuPosition({});
      setFileForContextMenu({});
      setOpenDrawer(false);
      setOpenPreview(false);
      setSelectedRowKeys([]);
      setOpenAskClearyAIDrawer(false);
    }
  }, [site, currentFolderId]);


  // The folders come from the backend as a flat list.  
  // Turn it into a list with child nodes.
  const structureFoldersAsTree = useCallback((folders, folderId = 0) => {
    let data = [];

    // console.log('structureFoldersAsTree', folders, folderId);

    for (let i = 0; i < folders.length; i++) {
      if (folders[i].parentId !== folderId) {
        continue;
      }

      // The root folder has no parent, and should be the only one
      // like that.
      if (folders[i].parentId === 0) {
        if (folders[i].name === 'ROOT') {
          folders[i].name = "Local Files";
          folders[i].icon = 'LaptopOutlined';
        } else {
          folders[i].icon = 'GlobalOutlined';
        }
      }

      let item = folders[i];
      item.children = structureFoldersAsTree(folders, folders[i].tableItemId);
      data.push(item);

    }
    if (data.length === 0) {
      return null;
    }
    return data;
  }, []);


  // Fetch both files and folders in the background (without displaying the loading spinners)
  // and then update the data.
  const silentRefresh = useCallback(() => {
    if (!currentFolderId) return;
    // TODO - maybe more gracefully update changes
    console.log('Silently getting new data');
    apiGetWithToken(getApiUrl(`/api/site/${site.siteId}/files/${currentFolderId}?includeFolders=1&refresh=1`),
      null,
      (data) => {
        console.log('new data', data);
        if (data.files) {
          setFiles(data.files);
        }
        if (data.folders) {
          const struct = structureFoldersAsTree(data.folders);
          if (struct !== null && struct.length) {
            setFolders(struct);
          }
        }
      },
      (err) => { console.error('error'); },
    );
  }, [site, currentFolderId, structureFoldersAsTree]);


  useEffect(() => {
    if (!site || !site.siteId || tableLastUpdateVersion === 0) return;
    console.log('tableLastUpdateVersion changed to ' + tableLastUpdateVersion + ', updating data...');
    silentRefresh();
  }, [site, tableLastUpdateVersion, silentRefresh]);


  useEffect(() => {
    if (!site || !site.siteId) return;

    console.log('Setting periodic timer to check for file updates');

    // Create an abort controller for the async api calls.
    const controller = new AbortController();
    const signal = controller.signal;


    function periodicVersionCheck() {
      if (!site || !site.siteId) return;
      if (signal.aborted) return;

      console.log('Checking for updates to files');

      const url = getApiUrl(`/api/site/${site.siteId}/files/version?stream=1`);
      apiGetWithToken(url,
        null,
        (data) => {
          // Reschedule another thread.
          if (site) {
            setTimeout(() => { periodicVersionCheck(); }, 5000);
          }
        },
        null,
        null,
        (progress, chunk) => {
          parseJsonChunk(chunk, (data) => {
            console.debug('Table data version ', data.tableLastUpdateVersion);
            // silentRefresh();
            setTableLastUpdateVersion(parseInt(data.tableLastUpdateVersion));
          });

        },
        signal
      );
    }

    // Wait 10s for first update.
    const timeoutId = setTimeout(() => { periodicVersionCheck(); }, 10000);

    // Cleanup function to clear the timeout if the component unmounts
    return () => {
      // Abort in progress api calls
      //console.debug('Clearing in progress version api calls');
      clearTimeout(timeoutId);
      controller.abort();
    }
  }, [site, silentRefresh]);


  useEffect(() => {
    if (!site) return;
    if (!site.siteId) return;
    if (!currentFolderId) return;

    console.log('Getting file/folder list');

    // Create an abort controller for the async api calls.
    const controller = new AbortController();
    const signal = controller.signal;

    // console.log('Getting file data');

    const includeFoldersVal = (reloadFiles || reloadFolders) ? 1 : 0;
    const refreshVal = reloadFiles ? 1 : 0;
    const forceReloadVal = forceReload ? 1 : 0;
    const url = getApiUrl(`/api/site/${site.siteId}/files/${currentFolderId}?includeFolders=${includeFoldersVal}&refresh=${refreshVal}&forceReload=${forceReloadVal}&syncRemote=1`);
    apiGetWithToken(url,
      () => {
        setLoadingFolders(includeFoldersVal > 0);
        setLoadingFiles(true);
      },
      (data) => {
        console.log('initial data', data);
        console.log('setting last version to ', data.tableLastUpdateVersion)
        setTableLastUpdateVersion(parseInt(data.tableLastUpdateVersion));
        if (data.files) {
          setFiles(data.files);
        }
        if (data.folders) {
          const struct = structureFoldersAsTree(data.folders);
          if (struct !== null && struct.length) {
            setFolders(struct);
            // console.log(struct[0]);
            if (struct.length) {
              setExpandedRowKeys([struct[0].tableItemId]);
            }
          }
        }
      },
      (err) => {
        messageApi.error(err.message); /*redirectToLogin(err);*/
      },
      (cancelled) => {
        if (!cancelled) {
          setReloadFiles(false);
          setReloadFolders(false);
          setLoadingFolders(false);
          setLoadingFiles(false);
        }
      },
      null,
      signal,
    );

    return () => {
      // Abort in progress apii calls
      //console.debug('Clearing in progress file api calls', controller);
      controller.abort();
    }

  }, [site, messageApi, currentFolderId, reloadFiles, reloadFolders, forceReload, structureFoldersAsTree]);



  // Determine if the current folder is part of a remote folder.
  useEffect(() => {

    function findFolder(folders) {
      for (let i = 0; i < folders.length; i++) {
        if (folders[i].tableItemId === currentFolderId) {
          return folders[i];
        }
        if (folders[i].children) {
          const f = findFolder(folders[i].children);
          if (f) return f;
        }
      }
      return null;
    }

    const selectedItem = findFolder(folders);
    let rootFolder = selectedItem;
    if (selectedItem && selectedItem.parentId) {
      for (let j = 0; j < folders.length; j++) {
        if (folders[j].tableItemId === selectedItem.parentId) {
          rootFolder = folders[j];
          break;
        }
      }
    }

    if (rootFolder && rootFolder.storageLocation && rootFolder.storageLocation !== '') {
      setIsRemoteFolder(true);
      setRemoteFolderType(rootFolder.storageLocation[0].toUpperCase() + rootFolder.storageLocation.slice(1));
    } else {
      setIsRemoteFolder(false);
    }

  }, [folders, currentFolderId]);




  function expandFolderKey(key) {
    // We need to go through the list of folders and find all the IDs that
    // need to expand in order to show the show we want.  It could be nested deep.

    function searchFolders(search) {
      if (!search) {
        return [];
      }
      for (let i = 0; i < search.length; i++) {
        if (search[i].tableItemId === key) {
          return [search[i].tableItemId];
        }
        let sub = searchFolders(search[i].children);
        if (sub.length) {
          sub.push(search[i].tableItemId);
          return sub;
        }
      }
      return [];
    }

    const ret = searchFolders(folders);
    let e = [...expandedRowKeys];
    ret.forEach((k) => {
      if (!e.includes(k)) {
        e.push(k);
      }
    })
    setExpandedRowKeys(e);
    // console.log('now', expandedRowKeys);
  }

  function onCellProperties(file) {
    return {
      style: { cursor: 'pointer' },
      onClick: (event) => {
        // console.log(event.target);
        // console.log(event.defaultPrevented);

        if (event.defaultPrevented) {
          return;
        }
        if (event.target.innerText === 'OK' || event.target.innerText === 'Cancel') {
          // Tag close buttons have this.
          return;
        }
        //event.preventDefault();
        if (file.isFolder) {
          console.log('User clicked on folder ' + file.name + ' (in file list)');
          console.log('Changing current folder from ' + currentFolderId + ' to ' + file.tableItemId);
          setCurrentFolderId(file.tableItemId);
          setSelectedRowKeys([]);
          expandFolderKey(file.tableItemId);
        } else {
          setFileForDrawer(file);
          setOpenDrawer(true);
        }
      },
      onDoubleClick: (event) => { }, // double click row
      onContextMenu: (event) => { /* onContextMenu(event, file);*/ }, // right button click row
      onMouseEnter: (event) => { }, // mouse enter row
      onMouseLeave: (event) => { }, // mouse leave row
    };
  }

  const clickViewFileDetails = useCallback((file) => {
    setFileForDrawer(file);
    setOpenDrawer(true);
  }, []);

  const clickPreviewFile = useCallback((file) => {
    setFileForPreview(file.tableItemId);
    setOpenPreview(true);
  }, []);



  const folderColumns = [
    {
      title: 'Folder',
      dataIndex: 'name',
      key: 'name',
      render: (_, folder) => {
        return <ClickableFile
          file={folder}
          currentFolderId={currentFolderId}
          messageApi={messageApi}
          onClick={() => {
          }}
        />
      },
    },
  ];

  const fileColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      //defaultSortOrder: 'ascend',
      sorter: (a, b) => a.name > b.name,
      onCell: (file, rowIndex) => {
        return onCellProperties(file);
      },
      render: (_, file) => {
        return (
          <>
            <Flex vertical>
              <Flex>
                <ClickableFile file={file} currentFolderId={currentFolderId} messageApi={messageApi} />
              </Flex>
              <Flex wrap>
                <FileTags file={file} />
              </Flex>
            </Flex>
          </>
        );
      },
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
      width: '100px',
      onCell: (file, rowIndex) => {
        return onCellProperties(file);
      },
      render: (_, file) => {
        if (file.isFolder || file.size < 0) {
          return <></>;
        }
        if (file.size > 1024 * 1024 * 1024) {
          return (file.size / (1024 * 1024 * 1024.0)).toFixed(2) + ' GB';
        }
        if (file.size > 1024 * 1024) {
          return (file.size / (1024 * 1024.0)).toFixed(2) + ' MB';
        }
        if (file.size > 1024) {
          return (file.size / 1024.0).toFixed(2) + ' KB';
        }
        return file.size + ' B';
      },
      sorter: (a, b) => a.size > b.size,
    },
    {
      title: 'Date Created',
      dataIndex: 'dateCreated',
      key: 'dateCreated',
      width: '120px',
      onCell: (file, rowIndex) => {
        return onCellProperties(file);
      },
      render: (_, file) => {
        if (file.dateCreated === null) {
          return (<></>);
        }
        return dayjs(file.dateCreated.date).format('MM/DD/YYYY');
      },
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      width: '25px',
      render: (_, file) => {
        if (file.prevFolder) {
          return <></>;
        }
        return (
          <ContextMenu file={file} viewFileDetails={clickViewFileDetails} previewFile={clickPreviewFile} refresh={silentRefresh}>
            <Button type="text" icon={<MoreOutlined />} onClick={(e) => { e.preventDefault(); }} />
          </ContextMenu>
        );
      },
    },
  ];

  // Find the parent folder of the given folderId
  function findParentOf(searchFolders, folderId, parent = null) {
    if (!searchFolders || !folderId) {
      return null;
    }
    for (let i = 0; i < searchFolders.length; i++) {
      const f = searchFolders[i];
      if (f.tableItemId === folderId) {
        return parent;
      }
      if (f.children && f.children.length) {
        let p = findParentOf(f.children, folderId, f);
        if (p) {
          return p;
        }
      }
    };
    return null;
  }

  if (files.length === 0 || !files[0].prevFolder) {
    const p = findParentOf(folders, currentFolderId);
    if (p !== null) {
      files.unshift(
        {
          name: '<prev folder>',
          prevFolder: true,
          tableItemId: p.tableItemId,
          isFolder: true,
          tags: [],
          dateCreated: null,
        }
      )
    }
  }

  const FolderTags = () => {
    if (isRemoteFolder) {
      return (
        <>
          <Tag icon={<GlobalOutlined />} color="green">Remote</Tag>
          <Tag icon={<CloudOutlined />} color="blue">{remoteFolderType}</Tag>
        </>
      )
    } else {
      return <></>
    }
  }

  return (
    <>
      {contextHolder}
      <UploadDialog site={site} siteInfo={siteInfo} currentFolderId={currentFolderId} setReloadFiles={setReloadFiles} isUploadOpen={isUploadOpen} setIsUploadOpen={setIsUploadOpen} refresh={silentRefresh} />
      <DeleteFiles site={site} open={isDeleteOpen} setOpen={setIsDeleteOpen} setSelectedRowKeys={setSelectedRowKeys} tableItemIds={tableItemIdsForComponent} refresh={silentRefresh} />
      <DownloadFiles site={site} open={isDownloadOpen} setOpen={setIsDownloadOpen} tableItemIds={tableItemIdsForComponent} />
      <AskClearyAI siteId={site.siteId} open={openAskClearyAIDrawer} setOpen={setOpenAskClearyAIDrawer} tableItemIds={tableItemIdsForComponent} />
      <CreateFolder siteId={site.siteId} parentId={currentFolderId} open={isCreateOpen} setOpen={setIsCreateOpen} refresh={silentRefresh} />
      <FileDrawer siteId={site.siteId} file={fileForDrawer} open={openDrawer} setOpen={setOpenDrawer} />
      <FilePreview siteId={site.siteId} tableItemId={fileForPreview} open={openPreview} setOpen={setOpenPreview} />
      <ContextMenu file={fileForContextMenu} open={isContextMenuOpen} pos={contextMenuPosition} messageApi={messageApi} />

      <Flex vertical style={{ padding: '0px', backgroundColor: '#fff', height: '100%', borderRadius: '8px' }}>
        <Flex style={{ padding: '10px', minWidth: '250px', backgroundColor: '#b6b8de', borderRadius: '8px 8px 0px 0px' }}>
          <Flex flex="1" gap="middle">
            <Button icon={<ReloadOutlined />} onClick={() => { setForceReload(true); setReloadFiles(true); setReloadFolders(true); }}>&nbsp;Refresh</Button>
            <Button icon={<UploadOutlined />} style={{ display: isRemoteFolder ? 'none' : 'inline' }} disabled={isRemoteFolder} onClick={() => { setIsUploadOpen(true); }}>&nbsp;Upload Files</Button>
            <Button icon={<FolderAddOutlined />} style={{ display: isRemoteFolder ? 'none' : 'inline' }} disabled={isRemoteFolder} onClick={() => { setIsCreateOpen(true); }} >&nbsp;Create Folder</Button>
            <Button icon={<CloudDownloadOutlined />} style={{ display: isRemoteFolder ? 'none' : 'inline' }} disabled={isRemoteFolder || selectedRowKeys.length === 0} onClick={() => { setTableItemIdsForComponent(selectedRowKeys); setIsDownloadOpen(true) }} >&nbsp;Download</Button>
            <Button icon={<DeleteOutlined />} style={{ display: isRemoteFolder ? 'none' : 'inline' }} disabled={isRemoteFolder || selectedRowKeys.length === 0} onClick={() => { setTableItemIdsForComponent(selectedRowKeys); setIsDeleteOpen(true); }} >&nbsp;Delete</Button>
            <Button icon={<CommentOutlined />} disabled={selectedRowKeys.length === 0} onClick={() => { setTableItemIdsForComponent(selectedRowKeys); setOpenAskClearyAIDrawer(true); }} >&nbsp;Ask ClearyAI</Button>
          </Flex>
          <Flex style={{ height: "25px" }}>
            <FolderTags />
          </Flex>
        </Flex>

        <Flex id="split" style={{ height: '100%' }}>
          <Flex vertical id="folders">
            <Table style={{ minWidth: '250px', height: '100%' }}
              rowKey='tableItemId'
              loading={loadingFolders}
              size="small"
              showHeader={false}
              columns={folderColumns}
              dataSource={folders}
              pagination={false}

              onRow={(folder, rowIndex) => {
                return {
                  style: { cursor: 'pointer' },
                  onClick: (ev) => {
                    console.log('Clicked on folder', folder.tableItemId);
                    setCurrentFolderId(folder.tableItemId);
                    console.log('Clearing selected row keys');
                    setSelectedRowKeys([]);
                  },
                  onDoubleClick: (event) => { event.preventDefault(); }, // double click row
                  onContextMenu: (event) => { event.preventDefault(); }, // right button click row
                  onMouseEnter: (event) => { }, // mouse enter row
                  onMouseLeave: (event) => { }, // mouse leave row
                };
              }}

              expandable={{
                expandRowByClick: true,
                expandedRowKeys: expandedRowKeys,
                onExpandedRowsChange: (rows) => {
                  //console.log('onChange', rows);
                  setExpandedRowKeys(rows);
                },
              }} />
          </Flex>
          <Flex flex="1" style={{ minWidth: '250px', borderLeft: '1px solid #ddd' }}>
            <Table style={{ width: '100%', height: '100%' }}
              rowKey='tableItemId'
              loading={loadingFiles}
              size="small"
              columns={fileColumns}
              dataSource={files}
              pagination={false}

              onRow={(file, rowIndex) => {
                return {
                  onClick: (event) => { }, // click row
                  onDoubleClick: (event) => { event.preventDefault(); }, // double click row
                  onContextMenu: (event) => { event.preventDefault(); }, // right button click row
                  onMouseEnter: (event) => { }, // mouse enter row
                  onMouseLeave: (event) => { }, // mouse leave row
                };
              }}

              rowSelection={{
                type: 'checkbox',
                getCheckboxProps: (file) => {
                  if (file.prevFolder)
                    return {
                      style: { display: 'none' },
                      disabled: true,
                    }
                },
                selectedRowKeys: selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                  setSelectedRowKeys(selectedRowKeys);
                  // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                },
              }}
            />
          </Flex>
        </Flex>
      </Flex >
      <font size="6px"><i>Data version {tableLastUpdateVersion}</i></font>
    </>
  );
};

export default function CXFileExplorer({ site, siteInfo, siteLoading, folderId }) {
  if (siteLoading || !site) {
    return <CXLoading text="Loading page..." />
  }

  return <CXFileExplorerComponent site={site} siteInfo={siteInfo} folderId={folderId} />
}


