import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Flex, Menu, Skeleton } from 'antd';
import { Link } from 'react-router-dom';
import { getApiUrl, apiGetWithToken } from 'lib/api';
import loadable from "@loadable/component";

function CXSidebar({ site, includeLogo }) {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);

  let params = useParams();
  const siteId = parseInt(params.siteId);

  useEffect(() => {
    if (isNaN(siteId)) {
      console.log('No siteId passed to component');
      window.location.href = "/login";
      return;
    }

    function createItems(data) {
      let items = [];
      data.forEach((item) => {

        if (item.label === '-') {
          items.push({ type: "divider" });
          return;
        }

        const DynamicIcon = loadable(props =>
          import(`@ant-design/icons/es/icons/${props.type}.js`)
            .catch(err => import(`@ant-design/icons/es/icons/WarningOutlined.js`)))

        let linkicon = '';
        let linklabel = item.label;
        if (item.icon && item.options) {
          linkicon = <Link to={item.options}><DynamicIcon type={item.icon} /></Link>;
        } else if (item.icon) {
          linkicon = <DynamicIcon type={item.icon} />;
        } else if (item.options) {
          //linkicon = <Link to={link}></Link>;
          linklabel = <Link to={item.options}>{item.label}</Link>;
        }

        // const icon = (item.options !== '') ?
        //   <Link to={item.options}><DynamicIcon type={item.icon} /></Link> :
        //   <DynamicIcon type={item.icon} />

        // Todo - handle children
        const i = {
          'key': item.label,
          'icon': linkicon,
          'children': (item.subMenus && item.subMenus.length) ? createItems(item.subMenus) : null,
          'label': linklabel,
        };
        items.push(i);
      });
      // console.log(items);
      return items;
    }

    apiGetWithToken(getApiUrl('/api/site/' + siteId + '/menu'),
      () => { setLoading(true); },
      (data) => { setItems(createItems(data.menu.items)); },
      (err) => { window.location.href = "/login"; },
      () => { setLoading(false); }
    );
  }, [siteId]);


  const styles = {
    skeleton: {
      margin: '20px',
    },
  };

  function InnerMenu() {
    if (loading) {
      return (
        <div style={styles.skeleton}><Skeleton paragraph={{ rows: 15, }} active /></div>
      );
    }
    return (
      <Menu
        style={{ height: '100%', width: '260px' }}
        theme="light"
        // defaultSelectedKeys={['0']}
        mode="inline"
        items={items}
        selectedKeys={selectedKeys}
        onClick={(v) => { setSelectedKeys([v.key]); }}
      />
    );
  }

  const logo = includeLogo ? (
    <Flex id="logo" style={{ margin: '30px 0px 20px 30px' }} >
      <img
        alt="logo"
        src='/static/CORA-Logo.png'
        style={{ maxHeight: '60px', maxWidth: '160px' }}
      />
    </Flex >
  ) : <></>;

  return (
    <Flex vertical style={{ height: '100%' }}>
      <Flex vertical flex="1" style={{ minWidth: '260px' }}>
        {logo}
        <InnerMenu />
      </Flex>
    </Flex>
  );
}

export default CXSidebar;
