import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import CXLoading from "components/CXLoading";
import { apiGetWithToken, generateQueryUrl } from 'lib/api';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import { hexColor } from 'lib/hexcolor';

const CXPieChart = ({ title, siteId, tableId, viewId, primary, secondary, filter, primaryLabel, secondaryLabels, limit, innerRadius, outerRadius, bgColors, bgStrokeColors, bgGradient, bgStrokeGradient, verboseLogging, setApiUrl, messageApi }) => {
  const [loading, setLoading] = useState(true);
  const [table, setTable] = useState([]);
  const [xAxisKey, setxAxisKey] = useState('');
  // const [xAxisName, setxAxisName] = useState('');
  const [yAxisKeys, setyAxisKeys] = useState([]);
  // const [yAxisNames, setyAxisNames] = useState([]);

  useEffect(() => {
    if (!loading) {
      verboseLogging && console.log('Not loading');
      return;
    }
    if (!siteId) {
      verboseLogging && console.log('No siteId');
      return;
    }
    if (!tableId && !viewId) {
      verboseLogging && console.log('No tableId or viewId');
      return;
    }

    function fillColors(passed, gradient, defaultColor, len) {
      let arr = passed ? passed.split(',') : [];
      for (let i = arr.length; i < len; i++) {
        if (i === 0) {
          arr[i] = defaultColor;
        } else if (gradient !== undefined) {
          const amount = parseInt(gradient) || 15;
          arr[i] = hexColor(arr[i - 1], amount);
        } else {
          arr[i] = arr[i - 1];
        }
      }
      return arr;
    }

    const url = generateQueryUrl(siteId, viewId, tableId, primary, secondary, filter, limit);
    setApiUrl && setApiUrl(url.toString());
    apiGetWithToken(url.toString(),
      () => { setLoading(true); },
      (data) => {
        let yKeys = [];
        let yNames = [];
        for (let i = 0; i < data.view.fieldList.length; i++) {
          if (i === 0) {
            setxAxisKey(data.view.fieldList[i].internalName);
            // setxAxisName(data.view.fieldList[i].name);
          } else {
            yKeys.push(data.view.fieldList[i].internalName);
            yNames.push(data.view.fieldList[i].name);
          }
        }
        setyAxisKeys(yKeys);
        // setyAxisNames(yNames);

        // Make sure we have enough of all the color values.
        const bgColorArr = fillColors(bgColors, bgGradient, '#55f', data.view.fieldValues.length);
        const bgStrokeArr = fillColors(bgStrokeColors, bgStrokeGradient, '#eee', data.view.fieldValues.length);

        // The pie chart looks for a "fill" property in the data list automatically to set
        // fill colors.
        for (let i = 0; i < data.view.fieldValues.length; i++) {
          // console.log(data.view.fieldValues[i]);
          // console.log(bgColorArr[i]);
          data.view.fieldValues[i].fill = bgColorArr[i];
          data.view.fieldValues[i].stroke = bgStrokeArr[i];
        }
        setTable(data.view.fieldValues);
      },
      (err) => {
        console.log(err);
        messageApi && messageApi.error(err.message);
      },
      () => { setLoading(false); }
    );

  }, [loading, siteId, tableId, viewId, bgColors, bgGradient, bgStrokeColors, bgStrokeGradient, filter, primary, secondary, limit, setApiUrl, verboseLogging, messageApi]);

  // console.log('xAxisKey', xAxisKey)
  // console.log('xAxisName', xAxisName)
  // console.log('yAxisKeys', yAxisKeys)
  // console.log('yAxisNames', yAxisNames)
  // console.log('table', table)

  const Inside = () => {
    if (loading) {
      return <CXLoading text="Loading graph..." />
    }

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
      return (table[index][xAxisKey] + ' ' + Math.round(percent * 100) + '%');
    };

    return (
      < ResponsiveContainer width={'99%'} height={300} >
        <PieChart width={600} height={600}  >
          <Pie
            labelLine
            label={renderCustomizedLabel}
            data={table}
            nameKey={xAxisKey}
            dataKey={yAxisKeys[0]}
            cx="50%"
            cy="50%"
            outerRadius={outerRadius}
            innerRadius={innerRadius}
          />
        </PieChart>
      </ResponsiveContainer >
    );
  }

  if (!title) {
    return <Inside />;
  }

  return (
    <>
      <Card title={title} style={{ width: '100%' }}>
        <Inside />
      </Card>
    </>
  );
};

export default CXPieChart;