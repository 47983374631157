import React, { useState, useEffect } from "react";
import { Spin, Drawer, Flex, message, Input } from 'antd';
import { MessageTwoTone, ExclamationCircleTwoTone } from '@ant-design/icons';
import { getApiUrl, apiGetWithToken, parseJsonChunk } from 'lib/api';
import Markdown from "react-markdown";

export function AskClearyAI({ siteId, open, setOpen, tableItemIds }) {
    const [messageApi, contextHolder] = message.useMessage();
    const [conversation, setConversation] = useState([]);
    const [queryId, setQueryId] = useState(0);
    const [status, setStatus] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [waitingForReponse, setWaitingForReponse] = useState(false);
    const [errorState, setErrorState] = useState(false);

    async function askQuestion(question) {
        //s('User asked a question');

        if (!open) return;

        let c = conversation;
        c.push(question);
        c.push('[Waiting for response]');
        setConversation(c);

        var url = new URL(getApiUrl(`/api/site/${siteId}/ai/${queryId}/ask`));
        url.searchParams.append('question', question);
        apiGetWithToken(url.toString(),
            () => {
                setWaitingForReponse(true);
                setStatus('Asking ClearyAI...');
            },
            (data) => {
                setStatus('ClearyAI is ready to answer your question.');
            },
            (err) => {
                messageApi.error('An error occurred getting the question response.');
                console.error('error');
            },
            () => {
                setWaitingForReponse(false);
            },
            (progress, chunk) => {
                setStatus('ClearyAI is answering...');

                // I don't get why we have to create an entirely new
                // array to trigger UI updates, but we do.  I'm betting there's a
                // better way.
                if (chunk.length) {
                    let c2 = [];
                    for (let i = 0; i < c.length - 1; i++) {
                        c2.push(c[i]);
                    }
                    c2.push(progress.trim());
                    setConversation(c2);
                    c = c2;

                    // Scroll to the bottom of the div
                    let divElement = document.getElementById('conversation');
                    //divElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
                    divElement.scrollTop = divElement.scrollHeight;
                }
            }
        );
    }


    useEffect(() => {
        if (!open && siteId && queryId) {
            // Cancel the chat session
            console.log("AskClearyAI drawer closed, cleaning up");
            apiGetWithToken(getApiUrl(`/api/site/${siteId}/ai/${queryId}/close`));
        }
    }, [siteId, open, queryId]);


    useEffect(() => {
        if (!open) return;
        if (!siteId) return;

        // Create an abort controller for the async api calls.
        const controller = new AbortController();
        const signal = controller.signal;

        setConversation([]);

        async function startConversation() {
            if (!open) return;
            let error = false;

            setConversation([]);
            setStatus("Getting everything warmed up");
            apiGetWithToken(getApiUrl(`/api/site/${siteId}/ai/start?tableItemIds=` + tableItemIds.join(',')),
                () => { setWaitingForReponse(true); },
                (data) => { },
                (err) => {
                    messageApi.error('An error occurred when asking the question.');
                    console.error('error');
                },
                () => {
                    if (error) {
                        setErrorState(true);
                    } else {
                        setWaitingForReponse(false);
                        setStatus('ClearyAI is ready to answer your question.');
                    }
                },
                (progress, chunk) => {
                    parseJsonChunk(chunk, (obj) => {
                        setStatus(obj.status);
                        if (obj.error > 0) {
                            error = true;
                        }
                        if (obj.aiQueryId > 0) {
                            //console.log('aiQueryId = ', obj.aiQueryId);
                            setQueryId(obj.aiQueryId);
                        }
                    });
                },
                signal,
            );
        }

        startConversation();

        // Cleanup function to clear the timeout if the component unmounts
        return () => {
            // Abort in progress api calls
            controller.abort();
        }

    }, [siteId, open, tableItemIds, messageApi]);

    const Conversations = () => {
        let count = 0;
        return (
            <Flex flex="1" vertical id="conversation" style={{ padding: '10px', width: '100%' }}>
                {conversation.map((i) => {
                    count++;
                    const arr = i.split(/[\r\n]+/);
                    // console.log(arr);
                    return arr.map((str) => {
                        if (count % 2 === 0) {
                            return (
                                <Flex>
                                    <Markdown>{str}</Markdown>
                                </Flex>
                            );
                        }
                        return (
                            <>
                                <Flex style={{ padding: '10px', width: '90%', backgroundColor: '#eee', border: '1px solid #aaa' }}><MessageTwoTone />&nbsp;<b>{str}</b></Flex>
                            </>
                        );
                    });
                })}
            </Flex>
        );
    }

    const StatusIcon = () => {
        if (errorState) {
            return <ExclamationCircleTwoTone />
        }
        if (waitingForReponse) {
            return <Spin />;
        } else {
            return <MessageTwoTone />;
        }
    }

    return (
        <>
            <Drawer width={540} placement="right" title="Ask ClearyAI" open={open} onClose={() => { setOpen(false); }}>
                <Flex vertical flex="1" style={{ height: "100%" }}>
                    <Flex style={{ paddingBottom: "20px" }}>
                        <Flex flex="1">{status}</Flex>
                        <Flex><StatusIcon /></Flex>
                    </Flex>
                    <Flex id="conversation" flex="1" style={{ overflowY: 'auto', width: '100%', backgroundColor: '#ddd', border: '1px #555 solid' }}>
                        <Conversations />
                    </Flex>
                    <Flex style={{ paddingTop: '10px' }}>
                        <Input
                            disabled={waitingForReponse}
                            style={{ padding: '5px' }}
                            placeholder="Ask ClearyAI your question here..."
                            allowClear
                            value={inputValue}
                            onChange={(e) => {
                                // console.log('onChange=', e);
                                setInputValue(e.target.value)
                            }}
                            onKeyDown={(e) => {
                                // console.log(e);
                                if (e.key === 'Enter') {
                                    askQuestion(e.target.value);
                                    setInputValue('');
                                }
                            }}
                        // onPressEnter={(e) => {
                        //     // e.preventDefault();
                        //     askQuestion(e.target.value);
                        //     console.log(e.target);
                        //     e.target.value = 'test';
                        // }}
                        />
                    </Flex>
                </Flex>

            </Drawer>
            {contextHolder}
        </>
    );
};

