import { ConfigProvider } from 'antd';

export default function Theme(props) {
    const borderRadius = 8;

    return (
        <ConfigProvider
            theme={{
                components: {
                    Button: {
                        borderRadius: borderRadius,
                    },
                    Table: {
                        //bodySortBg: 'red',
                        //headerBg: 'blue',
                        //headerColor: 'white',
                        headerBorderRadius: 0,
                    },
                    Layout: {
                        lightSiderBg: '#c0c0c0',
                        lightTriggerBg: '#aaaaaa',
                        lightTriggerColor: '#0a0a0a',
                    },
                    Menu: {
                        itemActiveBg: '#dddbea',
                        itemBg: '#fff',
                        subMenuItemBg: '#fff',
                        itemColor: '#333',
                        itemHoverColor: '#333',
                        itemHoverBg: '#dddbea',
                        itemSelectedColor: '#000',
                        itemSelectedBg: '#dddbea',

                        activeBarWidth: 0,
                        activeBarBorderWidth: 0,
                        itemBorderRadius: 0,
                    },
                    Breadcrumb: {
                        iconFontSize: 16,
                        itemColor: '#888',
                        lastItemColor: '#666',
                        linkColor: '#888',
                        linkHoverColor: '#444',
                        colorBgTextHover: '',
                        fontSize: '1.1em',
                    }
                },
                token: {
                    colorBgBase: "#eee",
                    colorBgContainer: "#fff",
                    colorPrimary: "#52637b",
                    colorInfo: "#52637b",
                    fontSize: 15,
                    fontFamily: "inter",
                    // font-optical-sizing: auto;
                    // font-weight: <weight>;
                    fontStyle: 'normal',

                    sizeStep: 4,
                    sizeUnit: 4,
                    borderRadius: borderRadius,

                },
            }}
        >
            {props.children}
        </ConfigProvider >
    );
}
