import React, { useCallback, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { message, Input, Select, Flex, Table } from 'antd';
import CXLoading from "components/CXLoading";
import { apiGetWithToken, getApiUrl } from "lib/api";
import CXBarchart from "components/CXBarchart";
import CXPieChart from "components/CXPieChart";
import CXHorizontalBarchart from "components/CXHorizontalBarchart";
import CXDatachart from "components/CXDatachart";

export default function GraphExplorer() {
    const { site, siteLoading, setBreadcrumb } = useOutletContext();
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(true);
    const [apiUrl, setApiUrl] = useState('');
    const [dataEntries, setDataEntries] = useState([]);

    const [currentGraph, setCurrentGraph] = useState('');
    const [currentTitle, setCurrentTitle] = useState('Sample Graph');
    const [currentTable, setCurrentTable] = useState('');
    const [currentPrimaryField, setCurrentPrimaryField] = useState('');
    const [currentPrimaryLabel, setCurrentPrimaryLabel] = useState('');
    const [currentFunction, setCurrentFunction] = useState('');
    const [currentSecondaryField, setCurrentSecondaryField] = useState('');
    const [currentSecondaryLabels, setCurrentSecondaryLabels] = useState('');
    const [currentFilterField, setCurrentFilterField] = useState('');
    const [currentFilterValue, setCurrentFilterValue] = useState('');
    const [currentParamValues, setCurrentParamValues] = useState({});

    const [rawTables, setRawTables] = useState([]);
    const [tableId, setTableId] = useState(0);
    const [viewId, setViewId] = useState(0);

    const [tables, setTables] = useState([]);
    const [fields, setFields] = useState([]);

    const setParam = useCallback((param, value) => {
        let copy = { ...currentParamValues };
        copy[param] = value;
        setCurrentParamValues(copy);
    }, [currentParamValues]);


    const getParam = useCallback((param) => {
        return currentParamValues[param] ?? '';
    }, [currentParamValues]);

    const setParamDefaults = useCallback((current, graphs) => {
        let g = null;
        graphs.forEach((v) => {
            if (v.value === current) {
                g = v;
            }
        });
        if (!g) {
            return;
        }
        //console.log(g);

        let newValues = {};
        g.params.forEach((v) => {
            // console.log(v.field, v.default);
            if (v.default !== undefined) {
                newValues[v.field] = v.default;
            }
        });
        setCurrentParamValues(newValues);

        //return currentParamValues[param] ?? '';
    }, [setCurrentParamValues]);


    useEffect(() => {
        if (siteLoading) return;

        apiGetWithToken(getApiUrl(`/api/site/${site.siteId}/tables?includeViews=1`),
            () => { setLoading(true); },
            (data) => {
                //console.log(data);
                const both = data.tables.concat(data.views);
                setRawTables(both);
                setTables(both.map((v) => {
                    return {
                        value: v.viewId ? "view:" + v.viewId : "table:" + v.tableId,
                        label: v.name
                    }
                }));
            },
            (err) => { messageApi.error(err); },
            () => { setLoading(false); }
        );

        return () => {
            setTables([]);
        }
    }, [siteLoading, site, setLoading, setTables, messageApi]);


    useEffect(() => {
        if (!apiUrl) return;

        apiGetWithToken(apiUrl,
            () => { },
            (data) => {
                const entries = data.table ? data.table.fieldValues : data.view.fieldValues;
                //console.log(entries);
                const show = Math.min(5, entries.length);
                setDataEntries(entries.slice(0, show));
            },
            (err) => { },
            () => { }
        );

        return () => { }
    }, [apiUrl]);


    useEffect(() => {
        if (currentTable.startsWith('view:')) {
            setTableId(0);
            const id = parseInt(currentTable.substring(5));
            setViewId(id);
            rawTables.forEach((v) => {
                if (v.viewId === id) {
                    setFields(v.fieldList.map((v) => {
                        return { value: v.name, label: v.name }
                    }));
                }
            });
        } else if (currentTable.startsWith('table:')) {
            const id = parseInt(currentTable.substring(6));
            setTableId(id);
            setViewId(0);
            rawTables.forEach((v) => {
                if (v.tableId === id) {
                    setFields(v.fieldList.map((v) => {
                        return { value: v.name, label: v.name }
                    }));
                }
            });
        } else {
            setTableId(0);
            setViewId(0);
            setFields([]);
        }

        return () => {
            setTableId(0);
            setViewId(0);
            setFields([]);
        }
    }, [currentTable, rawTables]);


    const graphs = [
        {
            value: 'Barchart',
            label: 'Barchart',
            element: 'CXBarchart',
            params: [
                { field: 'bgColors', desc: 'HTML colors for the bars', req: false, type: 'string' },
                { field: 'bgStrokeColors', desc: 'HTML colors for the stroke of the bars', req: false, type: 'string' },
                { field: 'activeBgColors', desc: 'HTML colors for the active bar', req: false, type: 'string' },
                { field: 'activeStrokeColors', desc: 'HTML colors for the stroke of the active bar', req: false, type: 'string' },
            ],
        },
        {
            value: 'HorizontalBarchart',
            label: 'Horizontal Barchart',
            element: 'CXHorizontalBarchart',
            params: [
                { field: 'bgColors', desc: 'HTML colors for the bars', req: false, type: 'string' },
                { field: 'bgStrokeColors', desc: 'HTML colors for the stroke of the bars', req: false, type: 'string' },
                { field: 'activeBgColors', desc: 'HTML colors for the active bar', req: false, type: 'string' },
                { field: 'activeStrokeColors', desc: 'HTML colors for the stroke of the active bar', req: false, type: 'string' },
            ],
        },
        {
            value: 'Piechart',
            label: 'Piechart',
            element: 'CXPiechart',
            params: [
                { field: 'innerRadius', desc: 'Inner radius for the circle', default: 20, req: false, type: 'int' },
                { field: 'outerRadius', desc: 'Outer radius for the circle', default: 80, req: false, type: 'int' },
                { field: 'bgColors', desc: 'HTML colors for the slices', default: '#8899dd', req: false, type: 'string' },
                { field: 'bgGradient', desc: 'Gradient change amount per slice', default: 10, req: false, type: 'string' },
                { field: 'bgStrokeColors', desc: 'HTML colors for the stroke of the slices', default: '#6677bb', req: false, type: 'string' },
                { field: 'bgStrokeGradient', desc: 'Gradient change amount for the stroke per slice', default: 10, req: false, type: 'int' },
            ],
        },
        {
            value: 'Datachart',
            label: 'Datachart',
            element: 'CXDatachart',
            params: [
                { field: 'limit', desc: 'Max rows to display', default: 5, req: false, type: 'int' },
            ],
        },
    ];


    useEffect(() => {
        if (siteLoading) return;
        setBreadcrumb({
            'Home': '',
            [site.name]: '/site/' + site.siteId,
            'Admin': '/admin/' + site.siteId,
            'Tables': '/admin/' + site.siteId + '/tables',
        });

        return () => { }
    }, [siteLoading, site, setBreadcrumb]);

    if (siteLoading || loading) {
        return <CXLoading text="Loading..." />
    }

    const functionFields = [
        { value: '', label: '<none>' },
        { value: 'count', label: 'Count' },
        { value: 'sum', label: 'Sum' },
    ];

    function chartProps() {
        let props = {}

        if (currentTitle) {
            props.title = currentTitle;
        }
        if (tableId > 0) {
            props.tableId = tableId;
        }
        if (viewId) {
            props.viewId = viewId;
        }
        props.primary = currentPrimaryField;
        if (currentPrimaryLabel) {
            props.primaryLabel = currentPrimaryLabel;
        }

        if (currentSecondaryField) {
            let secondary = '';
            switch (currentFunction) {
                case 'count':
                    secondary = 'COUNT(' + currentSecondaryField + ')';
                    break;
                default:
                    secondary = currentSecondaryField;
                    break;
            }
            props.secondary = secondary;
            if (currentSecondaryLabels) {
                props.secondaryLabels = currentSecondaryLabels;
            }
        }

        if (currentFilterField && currentFilterValue) {
            const filter = currentFilterField + '=' + currentFilterValue;
            props.filter = filter;
        }

        for (const param in currentParamValues) {
            props[param] = currentParamValues[param];
        }
        return props;
    }

    function SampleGraph() {
        if (currentTable === '' || (!viewId && !tableId)) {
            return <></>;
        }

        const props = chartProps();
        switch (currentGraph) {
            case 'Barchart':
                return (<>
                    <CXBarchart noverboseLogging messageApi={messageApi} siteId={site.siteId} setApiUrl={setApiUrl} {...props} />
                </>);
            case 'HorizontalBarchart':
                return (<>
                    <CXHorizontalBarchart noverboseLogging messageApi={messageApi} siteId={site.siteId} setApiUrl={setApiUrl} {...props} />
                </>);
            case 'Piechart':
                return (<>
                    <CXPieChart noverboseLogging messageApi={messageApi} siteId={site.siteId} setApiUrl={setApiUrl} {...props} />
                </>);
            case 'Datachart':
                return (<>
                    <CXDatachart noverboseLogging messageApi={messageApi} siteId={site.siteId} setApiUrl={setApiUrl} {...props} />
                </>);
            default:
                return <>No chart for {currentGraph}</>;
        }
    }

    function SampleGraphSource() {
        if (currentTable === '' || (!viewId && !tableId) || !currentPrimaryField) {
            return <></>;
        }

        const props = chartProps();
        let propStr = '';
        for (const prop in props) {
            propStr += prop + '="' + props[prop] + '" ';
        }

        let graphComponent = '';
        graphs.forEach((v) => {
            if (v.value === currentGraph) {
                graphComponent = v.element;
            }
        });

        return (
            '<' + graphComponent + ' ' + propStr + ' /> '
        );
    }

    function SampleTableData() {
        // console.log('data', dataEntries);

        if (dataEntries.length === 0) {
            // console.log('no data');
            return <></>;
        }

        let columns = [];
        for (const prop in dataEntries[0]) {
            columns.push(
                {
                    title: prop,
                    dataIndex: prop,
                    width: 100,
                    ellipsis: true,
                },
            );
            //console.log(prop);
        }

        return (
            <Table
                virtual
                columns={columns}
                scroll={{
                    x: 8000,
                    y: 200,
                }}
                // rowKey="id"
                dataSource={dataEntries}
                pagination={false}
            />
        );
    }


    function GraphOptions() {
        let g = null;
        graphs.forEach((v) => {
            if (v.label === currentGraph) {
                g = v;
            }
        });
        if (g === null) {
            return <></>;
        }
        // console.log(g);

        return (
            <Flex vertical style={{ display: currentTable === '' ? 'none' : 'inline', border: '1px solid #88f', padding: '10px', backgroundColor: '#eee' }}>
                {g.params.map((v) => {
                    return (
                        <>
                            <div>{v.desc}</div>
                            <Input
                                defaultValue={getParam(v.field)}
                                style={{ width: '200px' }}
                                // onChange={(val) => { setParam(v.field, val.target.value); }}
                                onBlur={(val) => { setParam(v.field, val.target.value); }}
                                onPressEnter={(val) => { setParam(v.field, val.target.value); }}
                            />
                            <br /><br />
                        </>
                    );
                })}
            </Flex>
        );
    }

    return (
        <>
            <h1>Graph Explorer</h1>
            <Flex gap="middle" style={{ width: '100%' }}>
                <Flex vertical gap="middle" style={{ width: '220px' }}>
                    <Flex vertical style={{ border: '1px solid #88f', padding: '10px' }}>
                        <div>Style of chart:</div>
                        <Select value={currentGraph} style={{ width: '200px' }} options={graphs} onChange={(v) => {
                            //console.log(v);
                            setCurrentGraph(v);
                            setCurrentParamValues({});
                            setParamDefaults(v, graphs);
                        }} />
                        <br />
                        <div>View/table to display:</div>
                        <Select value={currentTable} style={{ width: '200px' }} options={tables} onChange={(v) => {
                            setCurrentTable(v);
                            setCurrentPrimaryField('');
                            setCurrentSecondaryField('');
                            setCurrentFunction('');
                            setCurrentSecondaryLabels('')
                            setCurrentFilterField('');
                            setCurrentFilterValue('');
                        }} />
                    </Flex>

                    <Flex vertical style={{ display: currentTable === '' ? 'none' : 'inline', border: '1px solid #88f', padding: '10px', backgroundColor: '#eee' }}>
                        <div>Primary (x-axis) field:</div>
                        <Select value={currentPrimaryField} style={{ width: '200px' }} options={fields} onChange={(v) => {
                            setCurrentPrimaryField(v);
                            if (currentFunction !== '') {
                                setCurrentSecondaryField(v);
                            }
                        }} />
                        <br />
                        <div>Optional primary axis label:</div>
                        <div><Input defaultValue={currentPrimaryLabel} style={{ width: '200px' }} onBlur={(v) => { setCurrentPrimaryLabel(v.target.value); }} /></div>
                    </Flex>

                    <Flex vertical style={{ display: fields.length === 0 ? 'none' : 'inline', border: '1px solid #88f', padding: '10px', backgroundColor: '#eee' }}>
                        <Flex vertical>
                            <div>Optional aggregation method:</div>
                            <div><Select value={currentFunction} style={{ width: '200px' }} options={functionFields} onChange={(v) => {
                                setCurrentFunction(v);
                                if (v !== '') {
                                    setCurrentSecondaryField(currentPrimaryField);
                                }
                            }} /></div>
                            <br />
                            <div>Data (y-axis) field:</div>
                            <div><Select value={currentSecondaryField} disabled={currentFunction !== ''} style={{ width: '200px' }} options={fields} onChange={(v) => { setCurrentSecondaryField(v); }} /></div>
                            <br />
                            <div>Optional data axis label(s):</div>
                            <div><Input defaultValue={currentSecondaryLabels} style={{ width: '200px' }} onBlur={(v) => { setCurrentSecondaryLabels(v.target.value); }} /></div>
                        </Flex>
                    </Flex>

                    <Flex vertical style={{ display: fields.length === 0 ? 'none' : 'inline', border: '1px solid #88f', padding: '10px', backgroundColor: '#eee' }}>
                        <Flex vertical>
                            <div>Column to filter on, and its value:</div>
                            <div><Select value={currentFilterField} style={{ width: '200px' }} options={fields} onChange={(v) => { setCurrentFilterField(v); }} /></div>
                            <div><Input defaultValue={currentFilterValue} style={{ width: '200px' }} onBlur={(v) => { setCurrentFilterValue(v.target.value); }} onPressEnter={(v) => { setCurrentFilterValue(v.target.value); }} /></div>
                        </Flex>
                    </Flex>

                </Flex>

                <Flex vertical gap="middle" style={{ width: '220px' }}>
                    <Flex vertical style={{ border: '1px solid #88f', padding: '10px' }}>
                        <div>Title (leave blank for raw graph)</div>
                        <div>
                            <Input
                                defaultValue={currentTitle}
                                // value={titleRef.current.input.value}
                                style={{ width: '200px' }}
                                //onChange={(v) => { titleRef.current = v.target.value; }}
                                onBlur={(v) => { setCurrentTitle(v.target.value); }}
                                onPressEnter={(v) => { setCurrentTitle(v.target.value); }}
                            />
                        </div>
                    </Flex>
                    <GraphOptions />
                </Flex>

                <Flex gap="middle" flex="1" vertical style={{ width: '100px' }}>
                    <Flex flex="1" style={{ width: '100%' }}>
                        <SampleGraph />
                    </Flex>
                    <Flex style={{ backgroundColor: '#aaa', border: '1px solid #777', padding: '20px' }}>
                        <SampleGraphSource />
                    </Flex>
                    <Flex style={{ backgroundColor: '#aaa', border: '1px solid #777' }}>
                        <SampleTableData />
                    </Flex>
                </Flex>
            </Flex>
            {contextHolder}
        </>
    );
};

