import React, { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { List } from 'antd';
import CXLoading from "components/CXLoading";

const AdminGroups = () => {
    const { site, groups, siteLoading, setBreadcrumb } = useOutletContext();

    useEffect(() => {
        if (siteLoading) {
            return;
        }
        setBreadcrumb({
            'Home': '',
            [site.name]: '/site/' + site.siteId,
            'Admin': '/admin/' + site.siteId,
            'Groups': '/admin/' + site.siteId + '/groups',
        });
    }, [siteLoading, site, setBreadcrumb]);

    if (siteLoading) {
        return <CXLoading text="Loading groups..." />
    }

    return (
        <>
            <h1>Groups</h1>

            <List
                dataSource={groups}
                bordered
                size="large"
                renderItem={(group) => (
                    <List.Item>
                        <div>{group.name}</div>
                        {/* <Link to={{
                            pathname: `/admin/${site.siteId}/pages/${item.pageId}`,
                            state: { item },
                        }}>
                            Edit

                        </Link> */}
                    </List.Item >
                )}
            />
        </>
    );
};

export default AdminGroups;