import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Button, Input, message } from 'antd';
import { useOutletContext, useParams } from "react-router-dom";
import { getApiUrl, apiGetWithToken, apiPostWithToken, requireSiteId, redirectToLogin } from 'lib/api';
import CXLoading from "components/CXLoading";
import JoditEditor, { Jodit } from 'jodit-react';

const EditPage = ({ placeholder, create }) => {
    const editorRef = useRef(null);

    const [content, setContent] = useState('');
    const [title, setTitle] = useState('');

    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState({});

    const pageId = parseInt(useParams().pageId);
    const { site, siteLoading, setBreadcrumb } = useOutletContext();
    const [messageApi, contextHolder] = message.useMessage();

    function viewPage(pageId) {
        if (!pageId) {
            pageId = page.pageId;
        }
        window.location.href = '/site/' + site.siteId + '/page/' + pageId;
    }

    async function onClickSave() {
        console.log('Saving');
        console.log('Title', title);
        console.log('Content', content);

        const url = getApiUrl('/api/site/' + site.siteId + '/page/' + (create ? '' : pageId));
        apiPostWithToken(url, { title: title, content: content },
            () => { setSaving(true); },
            (data) => {
                messageApi.info('Page saved');
                console.log(data);
                viewPage(data.pageId);
            },
            (err) => { messageApi.error(err); /*redirectToLogin(err);*/ },
            () => { setSaving(false); }
        );
    }

    useEffect(() => {
        if (siteLoading) {
            return;
        }
        setBreadcrumb({
            'Home': '',
            [site.name]: '/site/' + site.siteId,
            'Pages': '/site/' + site.siteId + '/pages',
            'Edit Page': '',
        });

        if (!requireSiteId(site.siteId)) {
            return;
        }

        console.log('create', create);
        if (!create) {
            const url = getApiUrl(`/api/site/${site.siteId}/page/${pageId}?noCache=1`);
            apiGetWithToken(url,
                () => { setLoading(true); },
                (data) => {
                    console.log(data.page);
                    setPage(data.page);
                    setTitle(data.page.title);
                    setContent(data.page.content);
                },
                (err) => { messageApi.error(err); /*redirectToLogin(err);*/ },
                () => { setLoading(false); }
            );
        }

    }, [siteLoading, site, setBreadcrumb, pageId, messageApi, create]);

    console.log(Jodit.defaultOptions);

    const config = {
        readonly: false, // all options from https://xdsoft.net/jodit/docs/,
        placeholder: 'Start typing...',
        useSplitMode: true,
        //            defaultMode: 3,
        //mode: 'ace',

        // bauttons: [
        //     'source',
        //     '|',
        //     {
        //         group: 'font-style',
        //         buttons: []
        //     },
        //     {
        //         group: 'list',
        //         buttons: []
        //     },
        //     {
        //         group: 'font',
        //         buttons: []
        //     },
        //     '|',
        //     'brush',
        //     'image',
        //     'align',
        //     '|',
        //     'pageBreak',
        //     'exportDocs'
        // ],

        removeButtons: ['changeCase', 'classSpan', 'lineHeight'],
        buttons: [
            ...Jodit.defaultOptions.buttons,
            {
                name: 'insertChart',
                tooltip: 'Insert chart',
                exec: (editor) => {
                    editor.s.insertHTML('<cxbarchart title="Companies per Income Level" tableid="1001" viewid="1001" bgcolors="#9dd" activebgcolors="#8cc">');
                }
            }
        ],

        height: '500px',
        beautifyHTML: true,
        // sourceEditorCDNUrlsJS: [
        //     'https://cdnjs.cloudflare.com/ajax/libs/ace/1.4.4/ace.js'
        // ],
        // beautifyHTMLCDNUrlsJS: [
        //     'https://cdnjs.cloudflare.com/ajax/libs/js-beautify/1.10.0/beautify.min.js',
        //     'https://cdnjs.cloudflare.com/ajax/libs/js-beautify/1.10.0/beautify-html.min.js'
        // ]
    };



    if (loading || siteLoading) {
        return <CXLoading text="Loading page..." />
    }

    return (
        <>
            {contextHolder}
            Title:<br />
            <Input
                maxLength="250"
                placeholder="Enter a title for the page"
                defaultValue={title}
                onChange={(e) => { setTitle(e.target.value); }}
                disabled={saving}
            />
            <br /><br />

            Content:<br />
            <JoditEditor
                ref={editorRef}
                value={content}
                config={config}
                // tabIndex of textarea
                tabIndex={1}
                // preferred to use only this option to update the content for performance reasons
                onBlur={(newContent) => setContent(newContent)}
                onChange={(newContent) => { }}
            />
            <br />
            <Button type="primary" onClick={onClickSave} disabled={saving}>Save</Button>
            &nbsp;
            <Button onClick={viewPage} disabled={saving || create}>Cancel</Button>
        </>
    );
};

export default EditPage;