import React from 'react';
import { Spin, Flex } from 'antd';

function CXLoading({ text, width }) {
  width = width || '250px';
  text = text || 'Loading...';

  return (
    <Flex gap="small" vertical flex='1' align="center" justify="center" >
      <Flex gap="small">
        <Spin tip={text} size="large" style={{ width: width, backgroundColor: '#fff', border: "1px solid #ccc", borderRadius: '6px' }}>
          <div style={{ padding: '120px 10px 10px 120px' }} />
        </Spin>
      </Flex>
    </Flex>
  );
};

export default CXLoading;
