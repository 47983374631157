import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import { Flex } from 'antd';
import { LeftSquareFilled } from '@ant-design/icons';
import { Outlet } from "react-router-dom";
import { getApiUrl, apiGetWithToken, requireSiteId, redirectToLogin } from 'lib/api';
import AdminMenu from "./AdminMenu";
import CXBreadcrumb from 'components/CXBreadcrumb';
import CXAvatar from 'components/CXAvatar';
import CXSiteSelector from 'components/CXSiteSelector';

const AdminLayout = () => {
    const siteId = parseInt(useParams().siteId);
    const [breadcrumb, setBreadcrumb] = useState('');
    const [siteLoading, setSiteLoading] = useState(true);
    const [site, setSite] = useState(null);
    const [user, setUser] = useState(null);
    const [users, setUsers] = useState(null);
    const [groups, setGroups] = useState(null);
    const [roles, setRoles] = useState(null);

    useEffect(() => {
        if (!requireSiteId(siteId)) {
            return;
        }

        // Create an abort controller for the async api call.
        const controller = new AbortController();
        const signal = controller.signal;

        // todo - make menu work
        const url = getApiUrl('/api/site/' + siteId + '?includeAdminMenu=1&includeUser=1&includeGroups=1&includeRoles=1&includeUsers=1');
        apiGetWithToken(url,
            () => { setSiteLoading(true); console.log('Loading Site....'); },
            (data) => {
                // console.log('site results', data);
                setSite(data.site);
                setUser(data.user);
                setUsers(data.users);
                setGroups(data.groups);
                setRoles(data.roles);
            },
            (err) => { console.log('Error fetching site', err); redirectToLogin(err); },
            (cancelled) => {
                if (!cancelled) {
                    setSiteLoading(false);
                    console.log('Site loaded');
                } else {
                    console.log('Site load cancelled');
                }
            },
            null,
            signal,
        );

        // Cleanup function to clear the timeout if the component unmounts
        return () => {
            // Abort in progress apii calls
            // console.debug('Aborting site load');
            controller.abort();
            setSite(null);
        }

    }, [siteId]);

    const siteLink = site ? `/site/${site.siteId}` : '/';

    return (
        <Flex style={{ height: '100vh', minHeight: '100vh', maxHeight: '100vh' }} >
            <Flex id="left" vertical >
                <Flex id="menu">
                    <AdminMenu siteId={siteId} loading={siteLoading} includeLogo />
                </Flex>
            </Flex>

            <Flex
                flex="1"
                id="mainContent"
                vertical
                style={{ paddingBottom: '40px', paddingLeft: '40px', paddingRight: '40px', backgroundColor: '#f4f4f5', overflowY: 'auto' }}
            >

                <Flex id="topBar" style={{ margin: '20px 0 20px 0', height: '60px', minHeight: '60px', backgroundColor: '#fff', padding: '2px 40px 2px 40px', borderRadius: '8px', border: '1px solid #eee' }}>
                    <Flex align="center">
                        <Link to={siteLink}>
                            <LeftSquareFilled style={{ color: '#888', fontSize: '1.5em' }} />
                        </Link>
                    </Flex>
                    <Flex align="center" style={{ marginLeft: '20px', paddingLeft: '10px', paddingRight: '10px', color: '#888', fontSize: '1.2em' }}>
                        <CXSiteSelector site={site} user={user} />
                    </Flex>
                    <Flex>
                        <Flex align="center" style={{ marginLeft: '60px' }}>
                            <CXBreadcrumb breadcrumb={breadcrumb} />
                        </Flex>
                    </Flex>
                    <Flex align='center' justify="flex-end" flex="1">
                        <CXAvatar user={user} hasMenu></CXAvatar>
                    </Flex>
                </Flex>

                <div style={{ width: '100%', height: '100%' }}>
                    <Outlet context={{
                        siteId,
                        site,
                        setSite,
                        user,
                        users,
                        groups,
                        roles,
                        siteLoading,
                        setBreadcrumb,
                    }} />
                </div>
            </Flex>

        </Flex >
    );
};

export default AdminLayout;