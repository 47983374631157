import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Button, Flex, Table } from 'antd';
import { EditTwoTone } from '@ant-design/icons';
import CXLoading from "components/CXLoading";
import { apiGetWithToken, getApiUrl } from "lib/api";
import dayjs from 'dayjs';
import CXTableWrapper from "components/CXTableWrapper";
import { EditBundle } from "./EditBundle";

export default function AdminBundles() {
    const { site, siteLoading, setBreadcrumb } = useOutletContext();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const [bundles, setBundles] = useState([]);
    const [isEditBundleOpen, setIsEditBundleOpen] = useState(false);
    const [editBundle, setEditBundle] = useState(null);

    useEffect(() => {
        if (siteLoading || !site) return;
        setRefresh(false);

        // Create an abort controller for the async api call.
        const controller = new AbortController();
        const signal = controller.signal;

        apiGetWithToken(getApiUrl(`/api/site/${site.siteId}/bundles`),
            () => { setLoading(true); },
            (data) => { setBundles(data.bundles); },
            (err) => { },
            () => { setLoading(false); },
            null,
            signal,
        );

        return () => {
            controller.abort();
            setBundles([]);
            setLoading(true);
        }

    }, [siteLoading, site, refresh]);


    useEffect(() => {
        if (siteLoading || !site) return;

        setBreadcrumb({
            'Home': '',
            [site.name]: '/site/' + site.siteId,
            'Admin': '/admin/' + site.siteId,
            'Question Bundles': '/admin/' + site.siteId + '/bundles',
        });
    }, [siteLoading, site, setBreadcrumb]);

    if (siteLoading || loading) {
        return <CXLoading text="Loading..." />
    }

    function onCellProperties(bundle) {
        return {
            style: { cursor: 'pointer' },
            onClick: (ev) => {
                const link = `/admin/${site.siteId}/bundle/${bundle.bundleId}`;
                navigate(link);
            },
            onDoubleClick: (event) => { }, // double click row
            onContextMenu: (event) => { }, // right button click row
            onMouseEnter: (event) => { }, // mouse enter row
            onMouseLeave: (event) => { }, // mouse leave row
        };
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '240px',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.name > b.name,
            onCell: (bundle, rowIndex) => { return onCellProperties(bundle); },
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            sorter: (a, b) => a.description > b.description,
            onCell: (bundle, rowIndex) => { return onCellProperties(bundle); },
        },
        {
            title: 'Created',
            dataIndex: 'dateCreated',
            key: 'dateCreated',
            width: '120px',
            render: (_, bundle) => {
                if (!bundle.dateCreated) {
                    return (<></>);
                }
                return dayjs(bundle.dateCreated.date).format('MM/DD/YYYY');
            },
            onCell: (bundle, rowIndex) => { return onCellProperties(bundle); },
        },
        {
            title: 'Questions',
            dataIndex: 'questions',
            key: 'questions',
            width: '100px',
            onCell: (bundle, rowIndex) => { return onCellProperties(bundle); },
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            width: '40px',
            render: (_, bundle) => {
                return (
                    <>
                        <Button type="text" icon={<EditTwoTone />} onClick={(ev) => {
                            ev.preventDefault();
                            setEditBundle(bundle);
                            setIsEditBundleOpen(true);
                        }} />
                    </>
                );
            },
        },
    ];


    return (
        <>
            <EditBundle bundle={editBundle} setBundle={setEditBundle} site={site} setRefresh={setRefresh} open={isEditBundleOpen} setOpen={setIsEditBundleOpen} />

            <CXTableWrapper>
                <CXTableWrapper.Header>
                    <Flex flex="1">
                        <Flex flex="1">
                            <div style={{ fontSize: '1.25em', fontWeight: 'bold' }}> Question Bundles</div>
                        </Flex>
                        <Flex>
                            <Button onClick={() => { setEditBundle(null); setIsEditBundleOpen(true); }}>Create New Bundle</Button>
                        </Flex>
                    </Flex>
                </CXTableWrapper.Header>

                <Flex vertical flex="1">

                    <Table style={{ width: '100%', minWidth: '250px', borderWidth: '1px', borderStyle: 'solid', borderColor: '#e0e0e0', }}
                        rowKey='bundleId'
                        loading={loading}
                        size="middle"
                        // showHeader={false}
                        columns={columns}
                        dataSource={bundles}
                        pagination={false}
                    />
                </Flex>
                <CXTableWrapper.Footer>
                </CXTableWrapper.Footer>
            </CXTableWrapper>

        </>
    );
};
